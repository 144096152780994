// eslint-disable-next-line no-restricted-imports
import { createGlobalStyle } from 'styled-components'
import type { Theme } from '@sevenrooms/react-components/components/ThemeProvider'

export const renderOverrides = (theme: Theme) => createGlobalStyle`
    /* Header style */
    .fc .fc-view .fc-col-header-cell {
      background-color: ${theme.palette?.grey?.[50]} !important;
      border: none;
      color: ${theme.palette?.grey?.[600]};
      font-weight: normal;
      letter-spacing: 0.15em;
      text-transform: uppercase;
    }

    /* In-calendar nav hovers */
    .fc-dayGridMonth-view .fc-col-header-cell a:hover,
    .fc-timeGridDay-view .fc-col-header-cell a:hover {
      color: ${theme.palette?.grey?.[600]};
      cursor: auto;
      text-decoration: none;
    }
    .fc-timeGridWeek-view .fc-col-header-cell a:hover {
      cursor: pointer;
    }
    a.fc-daygrid-day-number:hover {
      cursor: pointer;
    }
  
    /* Border corrections */
    .fc .fc-scrollgrid {
      border: none; 
    }
    .fc .fc-scrollgrid table,
    .fc .fc-scrollgrid th,
    .fc .fc-scrollgrid .fc-scrollgrid-section > td {
      border-collapse: collapse;
      border-style: none;
    }
    .fc .fc-scrollgrid tbody {
      border: 1px solid ${theme.palette?.grey?.[200]};
    }
  
    /* Fix extraneous margin from global 7r style */
    .fc table {
      margin-bottom: 0;
    }
  
    /* Fix partial day events */
    a.fc-daygrid-dot-event:hover {
      background: none;
      color: inherit;
    }
    a.fc-daygrid-dot-event {
      margin-bottom: 2px;
      padding: 0;
    }
  
    /* Today highlight */
    .fc-day-today .fc-daygrid-day-number,
    .fc-day-today .date-text {
      background: ${theme.palette?.grey?.[500]};
      border-radius: 50%;
      color: ${theme.palette?.text?.primary};
    }
  
    /* Day grid styles */
    .fc td {
      border: 1px solid ${theme.palette?.grey?.[200]};
    }
    .fc .fc-daygrid-day-top {
      flex-direction: row;
    }
    .fc-daygrid-day-number {
      color: ${theme.palette?.grey?.[600]};
      display: inline-block;
      font-size: 10px;
      height: 22px;
      line-height: 1.4;
      margin: 2px;
      text-align: center;
      width: 22px;
    }
    .fc .fc-daygrid-day-frame {
      padding: 5px;
    }
    .fc .fc-view .fc-day-today {
      background-color: ${theme.palette?.grey?.[50]};
    }
    .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
      min-height: 7em;
    }
    .fc .fc-daygrid-more-link {
      color: ${theme.palette?.primary?.main};
      font-size: 14px;
    }
    .fc .fc-daygrid-more-link:hover {
      background: none;
    }
  
    /* Day grid past */
    .fc .fc-view .fc-day-past,
    .fc .fc-more-popover.fc-day-past,
    .fc .fc-more-popover.fc-day-past .fc-popover-header {
      background-color: ${theme.palette?.grey?.[100]}; 
    }
    .fc .fc-daygrid-day.fc-day-past .fc-daygrid-day-number {
      color: ${theme.palette?.grey?.[500]};
    }
    .fc .fc-daygrid-day.fc-day-past .fc-daygrid-day-top {
      opacity: 1;
    }
    .fc .fc-daygrid-day.fc-day-past .fc-event-past p,
    .fc .fc-more-popover .fc-event-past p {
      color: ${theme.palette?.grey?.[600]} !important;
    }
    .fc .fc-daygrid-day.fc-day-past .fc-event-past > div,
    .fc .fc-more-popover .fc-event-past > div {
      background-color: ${theme.palette?.grey?.[200]} !important;
    }
  
    /* Week Day Grid */
    .fc-timegrid .fc-timegrid-event {
      border: none;
      border-bottom: 1px solid ${theme.palette?.grey?.[50]};
      border-radius: 4px;
      box-shadow: none;
    }
    .fc-timegrid-event-harness-inset {
      margin-top: -2px;
    }
    .fc-timegrid .fc-col-header-cell span {
      display: block;
    }
    .fc-timegrid .fc-col-header-cell .dow-text {
      margin-left: 2px;
    }
    .fc-timegrid .fc-col-header-cell .date-text {
      font-weight: bold;
      height: 22px;
      letter-spacing: 0;
      line-height: 22px;
      margin: 4px auto 0;
      width: 22px;
    }
    .fc .fc-timegrid-body .fc-day-past .fc-event-past p {
      color: ${theme.palette?.grey?.[600]} !important;
    }
    .fc .fc-timegrid-body .fc-day-past .fc-event-past .fc-event-main > div {
      background-color: ${theme.palette?.grey?.[200]} !important;
    }
    .fc .fc-timegrid-slot-minor {
      border-top-style: none;
    }
    .fc-timegrid-slot-label-frame {
      background: ${theme.palette?.grey?.[50]};
      color: ${theme.palette?.grey?.[600]};
      font-size: 10px;
      left: -2px;
      letter-spacing: 0.15em;
      position: relative;
      text-transform: uppercase;
      top: -0.95em; 
    }
    .fc .fc-timegrid .fc-scroller {
      padding-top: 0.95em;
    }
    .fc td.fc-timegrid-slot-label,
    .fc .fc-timegrid .fc-scrollgrid tbody,
    .fc .fc-timegrid .fc-timegrid-col {
      border-left: none;
      border-left: none;
    }
  
    /* Day Time Grid */
    .fc-timeGridDay-view .fc-col-header-cell-cushion {
      float: left;
    }

    /* List view */
    .fc-list-event-time,
    .fc-list-event-graphic {
      display: none;
    }
    .fc .fc-list-table td {
      padding: 0;
      border: none;
    }
    .fc .fc-listWeek-view {
      border: none;
    }
    .fc .fc-list-table th {
      border: none;
    }
    .fc .fc-list-day-cushion {
      background-color: ${theme.palette?.grey?.[200]};
      border: none;
      border-radius: 4px;
      margin-top: ${theme.spacing(1)}; 
    }
    .fc .fc-day-today .fc-list-day-cushion {
      background-color: ${theme.palette?.grey?.[300]};
    }
    .fc .fc-day-past .fc-list-day-cushion {
      background-color: ${theme.palette?.grey?.[200]};
      color: ${theme.palette?.grey?.[500]};
    }
    .fc .fc-event-past {
      color: ${theme.palette?.grey?.[600]};
    }

    /* Popup */
    .fc .fc-popover {
      border-radius: 4px;
      overflow: hidden;
    }
    .fc .fc-popover-header {
      background: ${theme.palette?.grey?.[50]};
      border-bottom: 1px solid ${theme.palette?.grey?.[200]};
      font-size: 10px;
      padding: ${theme.spacing(2)} ${theme.spacing(3)};
    }
    .fc .fc-popover-title {
      letter-spacing: 0.15em;
      margin: 0;
      text-transform: uppercase;
    }
  `
