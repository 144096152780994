import { Link } from 'react-router-dom'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, SimplePopover } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import type { UserDomainVenue, Venue } from '@sevenrooms/mgr-core'
// eslint-disable-next-line import/no-relative-packages
import ContentLayout from '../../../../../application/site/static/app/component-lib/Manager/Layout/LanguageContent'
// eslint-disable-next-line import/no-relative-packages
import RowBlock from '../../../../../application/site/static/app/component-lib/Manager/NavigationBlocks/RowBlock/RowBlock'
// eslint-disable-next-line import/no-relative-packages
import RowBlockContainer from '../../../../../application/site/static/app/component-lib/Manager/NavigationBlocks/RowBlockContainer'
import { settingsMessages } from '../settings.locales'
import type { PropsWithChildren } from 'react'

interface LanguageSettingsProps {
  title: string
  venue: Venue
  venues: UserDomainVenue[]
  onChangeVenue: (venue: UserDomainVenue) => void
}

export function LanguageSettings({ children, ...props }: PropsWithChildren<LanguageSettingsProps>) {
  return <ContentLayout {...props}>{children}</ContentLayout>
}

export namespace LanguageSettings {
  interface BlockProps {
    label: string
  }

  export function Block({ label, children }: PropsWithChildren<BlockProps>) {
    return <RowBlockContainer label={label}>{children}</RowBlockContainer>
  }

  type LanguageSettingsItem = SimplePopover.PopoverChild

  interface RowProps {
    readonly id: string
    readonly label: string
    readonly editLink: string
    readonly addPlaceHolder?: boolean
    readonly blockText?: string
    readonly overrideIcons?: boolean
    readonly children?: LanguageSettingsItem | LanguageSettingsItem[]
  }

  export function Row(props: RowProps) {
    const { formatMessage } = useLocales()
    const { label, id, overrideIcons, blockText, editLink, addPlaceHolder = false, children } = props
    const content = !children ? (
      addPlaceHolder && <Box width="42px" height="42px" />
    ) : (
      <SimplePopover alignment="bottomRight">{children}</SimplePopover>
    )
    return (
      <RowBlock label={label} id={id} overrideIcons={overrideIcons} textOverride={blockText} disableIconHover>
        <Link to={editLink}>
          <Button icon="VMSWeb-edit" variant="secondary" size="s" data-test="edit-button">
            {formatMessage(settingsMessages.edit)}
          </Button>
        </Link>
        {content}
      </RowBlock>
    )
  }

  export const { PopoverItem: MenuItem } = SimplePopover
}
