import { TimeLocale } from '@sevenrooms/core/timepiece'

export const listTime = (start: Date | null, end: Date | null) => {
  const formatter = new Intl.DateTimeFormat(TimeLocale.getLocale(), { hour: 'numeric', minute: 'numeric' })
  const startArr = formatter.format(start || undefined).split(' ')
  const endArr = formatter.format(end || undefined).split(' ')

  if (startArr.length === 2 && startArr[1] === endArr[1]) {
    startArr.pop()
  }

  return `${startArr.join('')}-${endArr.join('')}`.toLowerCase()
}
