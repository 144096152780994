import type { AvailabilityDebuggerReason } from '@sevenrooms/core/domain'

export function filterDuplicateReasons(reasons: AvailabilityDebuggerReason[]): AvailabilityDebuggerReason[] {
  const seenReasons = new Set<string>()
  return reasons.filter(reason => {
    if (seenReasons.has(reason.reason)) {
      return false
    }
    seenReasons.add(reason.reason)
    return true
  })
}
