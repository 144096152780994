import {
  ProductProvisionPackageTypeEnum,
  type MetricPositiveFeedback,
  type MetricCampaignCount,
  type MetricNoShows,
  type MetricCancellations,
  type MetricCovers,
  type MetricEventsPrepaid,
  type MetricExperiences,
  type MetricPrimaryVenue,
  type MetricCrossReservations,
  type MetricUpgrades,
  type MetricUpgradesPrepayment,
  type RevenueSource,
} from '@sevenrooms/core/domain'
import type { Venue, VenueSettings, User } from '@sevenrooms/mgr-core'
import { RevenueTypeEnum } from '../constants'
import ImageCancellation from '../images/revenue/cancellation.png'
import ImageAutomatedEmail from '../images/revenue/email_automation.png'
import ImageEmailMarketing from '../images/revenue/email_marketing.png'
import ImageEvents from '../images/revenue/event.png'
import ImageMultiVenueWidgets from '../images/revenue/multi_venue.png'
import ImageOfferExperiences from '../images/revenue/offer_experiences.png'
import ImagePrepayments from '../images/revenue/other_prepayment.png'
import ImageTextMarketing from '../images/revenue/text_marketing.png'
import ImageUpgrades from '../images/revenue/upgrades.png'
import { reportingMessages } from '../locales'

const composeDemoParams = (user?: User, venueSquid?: string) => {
  if (!user || !venueSquid) {
    return ''
  }

  return new URLSearchParams({
    utm_source: 'PLG',
    utm_content: 'revdashboard',
    firstname: user.first_name,
    lastname: user.last_name,
    email: user.email,
    squid: venueSquid,
  }).toString()
}

export const getRevenueTypeInfo = (revenueType: string, venue: Venue, venueSettings: VenueSettings, user?: User, venueSquid?: string) => {
  const isEssentials = [ProductProvisionPackageTypeEnum.ESSENTIALS, ProductProvisionPackageTypeEnum.ESSENTIALS_TRIAL].includes(
    venue.productProvisionPackage as ProductProvisionPackageTypeEnum
  )

  switch (revenueType) {
    case RevenueTypeEnum.EMAIL_MARKETING:
      return {
        title: reportingMessages.titleEmailMarketing,
        aboutText: reportingMessages.aboutTextEmailMarketing,
        mainLink:
          'https://help.sevenrooms.com/hc/en-us/search/click?data=BAh7DjoHaWRsKwib680gWgY6D2FjY291bnRfaWRpAws2IToJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSJUaHR0cHM6Ly9oZWxwLnNldmVucm9vbXMuY29tL2hjL2VuLXVzL2FydGljbGVzLzY5ODQxNjcxODk0MDMtRW1haWwtTWFya2V0aW5nLTEwMQY7CFQ6DnNlYXJjaF9pZEkiKWQyYzRiYTA3LTJlNmYtNDFhOC1iZmJiLWRlZmQ3NjM4NWIwZAY7CEY6CXJhbmtpBjoLbG9jYWxlSSIKZW4tdXMGOwhUOgpxdWVyeUkiFGVtYWlsIG1hcmtldGluZwY7CFQ6EnJlc3VsdHNfY291bnRpAdw%3D--47a96807f653a052b5dca25b78b0830744d6bbf5',
        learnLink:
          'https://help.sevenrooms.com/hc/en-us/search/click?data=BAh7DjoHaWRsKwib680gWgY6D2FjY291bnRfaWRpAws2IToJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSJUaHR0cHM6Ly9oZWxwLnNldmVucm9vbXMuY29tL2hjL2VuLXVzL2FydGljbGVzLzY5ODQxNjcxODk0MDMtRW1haWwtTWFya2V0aW5nLTEwMQY7CFQ6DnNlYXJjaF9pZEkiKWQyYzRiYTA3LTJlNmYtNDFhOC1iZmJiLWRlZmQ3NjM4NWIwZAY7CEY6CXJhbmtpBjoLbG9jYWxlSSIKZW4tdXMGOwhUOgpxdWVyeUkiFGVtYWlsIG1hcmtldGluZwY7CFQ6EnJlc3VsdHNfY291bnRpAdw%3D--47a96807f653a052b5dca25b78b0830744d6bbf5',
        outPackageLink: `https://sevenrooms.com/customer-email-marketing-request-product/?channel=emailmarketing&${composeDemoParams(
          user,
          venueSquid
        )}`,
        exploreLink: `/manager2/${venue.urlKey}/marketing/email-campaigns/`,
        image: ImageEmailMarketing,
        metricTitle1: 'Top Campaign',
        metricTitle2: 'Total Campaigns',
        locked: !venueSettings.product_provision_addon_email_campaigns,
        isUpgrade: true,
      }
    case RevenueTypeEnum.AUTOMATED_EMAILS:
      return {
        title: reportingMessages.titleAutomatedEmails,
        aboutText: reportingMessages.aboutTextAutomatedEmails,
        mainLink: 'https://help.sevenrooms.com/hc/en-us/articles/360024403272-Automations-101',
        learnLink: 'https://help.sevenrooms.com/hc/en-us/articles/360024403272-Automations-101',
        outPackageLink: 'https://help.sevenrooms.com/hc/en-us/articles/360024403272-Automations-101',
        exploreLink: `/manager2/${venue.urlKey}/marketing/email-center/emails/`,
        image: ImageAutomatedEmail,
        metricTitle1: 'Top Campaign',
        metricTitle2: 'Active Campaigns',
        locked: false,
        isUpgrade: false,
      }
    case RevenueTypeEnum.EXPERIENCE_OFFERS:
      return {
        title: reportingMessages.titleOfferExperiences,
        aboutText: reportingMessages.aboutTextOfferExperiences,
        mainLink: 'https://help.sevenrooms.com/hc/en-us/articles/360027285472-Creating-Offers-Experiences-and-Events',
        learnLink: 'https://help.sevenrooms.com/hc/en-us/articles/360027285472-Creating-Offers-Experiences-and-Events',
        outPackageLink: 'https://help.sevenrooms.com/hc/en-us/articles/360027285472-Creating-Offers-Experiences-and-Events',
        exploreLink: `/manager2/${venue.urlKey}/reporting/embed/dashboards/395`,
        image: ImageOfferExperiences,
        metricTitle1: 'Top Experience',
        metricTitle2: 'Active Experiences',
        locked: false,
        isUpgrade: false,
      }
    case RevenueTypeEnum.UPGRADE:
      return {
        title: reportingMessages.titleUpgrades,
        aboutText: reportingMessages.aboutTextUpgrades,
        mainLink: 'https://help.sevenrooms.com/hc/en-us/articles/360007546972-How-to-Create-Upgrades',
        learnLink: 'https://help.sevenrooms.com/hc/en-us/articles/360007546972-How-to-Create-Upgrades',
        outPackageLink: 'https://help.sevenrooms.com/hc/en-us/articles/360007546972-How-to-Create-Upgrades',
        exploreLink: `/manager2/${venue.urlKey}/reporting/embed/dashboards/335`,
        image: ImageUpgrades,
        metricTitle1: 'Top Upgrade',
        metricTitle2: 'Prepaid Upgrades',
        locked: false,
        isUpgrade: false,
      }
    case RevenueTypeEnum.OTHER_PREPAYMENTS:
      return {
        title: reportingMessages.titlePrepayments,
        aboutText: reportingMessages.aboutTextPrepayments,
        mainLink: 'https://help.sevenrooms.com/hc/en-us/articles/360006844272-Charging-and-Saving-Credit-Cards-Web',
        learnLink: 'https://help.sevenrooms.com/hc/en-us/articles/360006844272-Charging-and-Saving-Credit-Cards-Web',
        outPackageLink: 'https://help.sevenrooms.com/hc/en-us/articles/360006844272-Charging-and-Saving-Credit-Cards-Web',
        exploreLink: null,
        image: ImagePrepayments,
        metricTitle1: null,
        metricTitle2: null,
        locked: false,
        isUpgrade: false,
      }
    case RevenueTypeEnum.CANCELLATION_AND_NO_SHOW_FEE:
      return {
        title: reportingMessages.titleCancellationFees,
        aboutText: reportingMessages.aboutTextCancellationFees,
        mainLink: 'https://help.sevenrooms.com/hc/en-us/articles/7927927091867-Access-Rule-Payment-and-Policy#h_01GAVKG8YE5FQS9VNQ1WYWTR1A',
        learnLink: 'https://help.sevenrooms.com/hc/en-us/articles/11486200820123-Shift-Payment-Policies-Settings',
        outPackageLink:
          'https://help.sevenrooms.com/hc/en-us/articles/7927927091867-Access-Rule-Payment-and-Policy#h_01GAVKG8YE5FQS9VNQ1WYWTR1A',
        exploreLink: `/manager2/${venue.urlKey}/reporting/embed/dashboards/397`,
        image: ImageCancellation,
        metricTitle1: 'No-Show Rate',
        metricTitle2: 'Cancellation Rate',
        locked: false,
        isUpgrade: false,
      }
    case RevenueTypeEnum.EVENTS:
      return {
        title: reportingMessages.titleEvents,
        aboutText: reportingMessages.aboutTextEvents,
        mainLink: 'https://help.sevenrooms.com/hc/en-us/articles/360007507091-How-To-Creating-Events-in-the-Event-Widget',
        learnLink: 'https://help.sevenrooms.com/hc/en-us/articles/360007507091-How-To-Creating-Events-in-the-Event-Widget',
        outPackageLink: 'https://help.sevenrooms.com/hc/en-us/articles/360007507091-How-To-Creating-Events-in-the-Event-Widget',
        exploreLink: `/manager/${venue.urlKey}/events`,
        image: ImageEvents,
        metricTitle1: 'Top Event',
        metricTitle2: 'Prepaid Events',
        locked: isEssentials,
        isUpgrade: false,
      }
    case RevenueTypeEnum.MULTI_VENUE_WIDGETS:
      return {
        title: reportingMessages.titleMultiVenueWidgets,
        aboutText: reportingMessages.aboutTextMultiVenueWidgets,
        mainLink: 'https://help.sevenrooms.com/hc/en-us/articles/25693056739867-Cross-Promotion-Widget-New-Widget',
        learnLink: 'https://help.sevenrooms.com/hc/en-us/articles/25693056739867-Cross-Promotion-Widget-New-Widget',
        outPackageLink: 'https://help.sevenrooms.com/hc/en-us/articles/25693056739867-Cross-Promotion-Widget-New-Widget',
        exploreLink: `/manager2/${venue.urlKey}/reporting/embed/dashboards/299`,
        image: ImageMultiVenueWidgets,
        metricTitle1: 'Top Venue Promoter',
        metricTitle2: 'Reservations from Cross-Promotions',
        locked: isEssentials,
        isUpgrade: false,
      }
    case RevenueTypeEnum.TEXT_MARKETING:
      return {
        title: reportingMessages.titleTextMarketing,
        aboutText: reportingMessages.aboutTextTextMarketing,
        mainLink:
          'https://sevenrooms.com/text-marketing-request/?Detail=product&Detail-MR=product&utm_source=product&utm_medium=website&utm_campaign=revenue_reporting',
        learnLink: 'https://help.sevenrooms.com/hc/en-us/articles/25724403935387-Text-Marketing-101',
        outPackageLink: `https://sevenrooms.com/customer-text-marketing-request-product/?channel=textmarketing&${composeDemoParams(
          user,
          venueSquid
        )}`,
        exploreLink: `/manager2/${venue.urlKey}/marketing/sms-campaigns/`,
        image: ImageTextMarketing,
        metricTitle1: 'Top Campaign',
        metricTitle2: 'Active Campaigns',
        locked: !venueSettings.product_provision_addon_sms_marketing,
      }
    default:
      return {
        title: null,
        aboutText: null,
        mainLink: null,
        learnLink: null,
        outPackageLink: null,
        exploreLink: null,
        image: null,
        metricTitle1: null,
        metricTitle2: null,
        locked: false,
        isUpgrade: false,
      }
  }
}

export const getMetricValues = (source: RevenueSource) => {
  if (!source || !source.topMetric1 || !source.topMetric2) {
    return [null, null]
  }
  let topMetric1
  let topMetric2
  switch (source.type) {
    case RevenueTypeEnum.EMAIL_MARKETING:
      topMetric1 = source.topMetric1 as MetricPositiveFeedback
      topMetric2 = source.topMetric2 as MetricCampaignCount
      return [topMetric1.name, topMetric2.campaignsCount]
    case RevenueTypeEnum.AUTOMATED_EMAILS:
      topMetric1 = source.topMetric1 as MetricPositiveFeedback
      topMetric2 = source.topMetric2 as MetricCampaignCount
      return [topMetric1.name, topMetric2.campaignsCount]
    case RevenueTypeEnum.EXPERIENCE_OFFERS:
      topMetric1 = source.topMetric1 as MetricCovers
      topMetric2 = source.topMetric2 as MetricExperiences
      return [topMetric1.name, topMetric2.experiencesActiveCount]
    case RevenueTypeEnum.UPGRADE:
      topMetric1 = source.topMetric1 as MetricUpgrades
      topMetric2 = source.topMetric2 as MetricUpgradesPrepayment
      return [topMetric1.upgradeName, topMetric2.upgradesWithPrepayment]
    case RevenueTypeEnum.CANCELLATION_AND_NO_SHOW_FEE:
      topMetric1 = source.topMetric1 as MetricNoShows
      topMetric2 = source.topMetric2 as MetricCancellations
      return [topMetric1.noShowsPct, topMetric2.cancellationsPct]
    case RevenueTypeEnum.EVENTS:
      topMetric1 = source.topMetric1 as MetricCovers
      topMetric2 = source.topMetric2 as MetricEventsPrepaid
      return [topMetric1.name, topMetric2.eventsPrepaid]
    case RevenueTypeEnum.MULTI_VENUE_WIDGETS:
      topMetric1 = source.topMetric1 as MetricPrimaryVenue
      topMetric2 = source.topMetric2 as MetricCrossReservations
      return [topMetric1.primaryVenueName, topMetric2.reservationsFromCrossPromotion]
    case RevenueTypeEnum.TEXT_MARKETING:
      topMetric1 = source.topMetric1 as MetricPositiveFeedback
      topMetric2 = source.topMetric2 as MetricCampaignCount
      return [topMetric1.name, topMetric2.campaignsCount]
    default:
      return [null, null]
  }
}
