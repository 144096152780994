import { Box } from '@sevenrooms/react-components/components/Box'
import { useTheme } from '@sevenrooms/react-components/hooks/useTheme'

export function StatusIcon({ status }: { status: string }) {
  const theme = useTheme()
  const details = {
    booked: {
      iconName: 'book-line',
      background: theme.palette.grey[300],
    },
    confirmed: {
      iconName: 'book',
      background: theme.palette.grey[50],
    },
    pendingDetails: {
      iconName: 'snooze',
      background: '#74B5EB',
    },
    paused: {
      iconName: 'pause',
      background: theme.palette.grey[300],
    },
    cancelled: {
      iconName: 'close',
      background: '#DB3859',
    },
    request: {
      iconName: 'request',
      background: '#FFC78E',
    },
  }[status]

  return (
    <Box
      sx={{
        backgroundColor: details?.background,
        border: status === 'confirmed' ? '1px solid #eee' : 'none',
        borderRadius: '50%',
        color: status === 'cancelled' ? theme.palette.common.white : theme.palette.text.primary,
        display: 'inline-block',
        fontSize: 9,
        height: 16,
        lineHeight: '16px',
        textAlign: 'center',
        width: 16,
      }}
    >
      <i className={`svr-icon- svr-icon--${details?.iconName}`} />
    </Box>
  )
}
