import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconButton, type IconButtonProps } from '@sevenrooms/react-components/components/IconButton'
import { styled } from '@sevenrooms/react-components/components/ThemeProvider'

type ExpandMoreProps = {
  expand: boolean
} & IconButtonProps

export const ExpandMore = styled((props: ExpandMoreProps) => (
  <IconButton {...props} data-test="expand-more">
    <ExpandMoreIcon />
  </IconButton>
))(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}))
