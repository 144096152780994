import type { AvailabilityDebuggerReason, AvailabilityDebuggerReasonAccessRule } from '@sevenrooms/core/domain'

export type GroupByCategory = Record<string, AvailabilityDebuggerReason[]>

export function initializeGroupByCategory(): GroupByCategory {
  return {
    VENUE: [],
    SHIFTS: [],
    ACCESS_RULE_MATCH: [],
    ACCESS_RULE_TARGETING: [],
    TABLES_MATCH: [],
    TABLES_TARGETING: [],
    COMBO_MATCH: [],
    COMBO_TARGETING: [],
    INACTIVE: [],
  }
}

// Return any full stop reasons present in the grouping. Some full stop reasons return for every table,
// we want to capture all tables and we de-dupe when necessary during content rendering
function filterByFullStop(allReasons: AvailabilityDebuggerReason[]): AvailabilityDebuggerReason[] {
  const fullStopReasons = ['VENUE_CLOSED', 'NO_MATCHING_SHIFTS', 'NO_ACCESS_RULES_FOR_SHIFT', 'OVER_MAX_SHIFT_COVERS']

  let hasFullStopReason = false

  const filteredReasons = allReasons.filter(reason => {
    if (reason.reason && fullStopReasons.includes(reason.reason)) {
      hasFullStopReason = true
      return true
    }
    return false
  })

  return hasFullStopReason ? filteredReasons : allReasons
}

export function groupReasonsByCategory(allReasons: AvailabilityDebuggerReason[]) {
  if (allReasons.length < 1) {
    return null
  }

  const filteredAllReasons = filterByFullStop(allReasons)

  const gbc: GroupByCategory = initializeGroupByCategory()
  filteredAllReasons?.forEach(r => {
    const cat = r.category
    if (cat in gbc) {
      gbc[cat] = [...(gbc[cat] ?? []), r]
    }
  })

  return gbc
}

export interface GroupedAvailabilityDebuggerReasonsByAccessRule {
  [key: string]: GroupedAvailabilityDebuggerReasons
}

export interface GroupedAvailabilityDebuggerReasons {
  accessRuleId: string
  accessRuleName: string
  accessPersistentId: string
  hasAccessRuleMatchReasons: boolean
  accessRuleMatchReasons: AvailabilityDebuggerReason[]
  accessRuleTargetingReasons: AvailabilityDebuggerReason[]
  tablesMatchReasons: AvailabilityDebuggerReason[]
  tablesTargetingReasons: AvailabilityDebuggerReason[]
  comboMatchReasons: AvailabilityDebuggerReason[]
  comboTargetingReasons: AvailabilityDebuggerReason[]
}

export function groupReasonsByAccessRule(
  allReasons: AvailabilityDebuggerReason[],
  allAccessRules: AvailabilityDebuggerReasonAccessRule[]
): GroupedAvailabilityDebuggerReasonsByAccessRule | null {
  const reasonsByAccessRule: GroupedAvailabilityDebuggerReasonsByAccessRule = {}
  const reasonsByCategory = groupReasonsByCategory(allReasons)
  const accessRuleIds = allAccessRules.map(ar => ar.accessRuleId)

  allAccessRules.forEach(accessRule => {
    reasonsByAccessRule[accessRule.accessRuleId] = {
      accessRuleId: accessRule.accessRuleId,
      accessRuleName: accessRule.accessRuleName,
      accessPersistentId: accessRule.accessPersistentId,
      hasAccessRuleMatchReasons: false,
      accessRuleMatchReasons: [],
      accessRuleTargetingReasons: [],
      tablesMatchReasons: [],
      tablesTargetingReasons: [],
      comboMatchReasons: [],
      comboTargetingReasons: [],
    }
  })

  if (!reasonsByCategory) {
    return reasonsByAccessRule
  }

  Object.entries(reasonsByCategory).forEach(([, reasons]) => {
    reasons.forEach(r => {
      const accessRuleId = r.data?.accessRuleId

      if (r.category === 'ACCESS_RULE_MATCH' && accessRuleId && reasonsByAccessRule[accessRuleId]) {
        reasonsByAccessRule[accessRuleId]?.accessRuleMatchReasons.push(r)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        reasonsByAccessRule[accessRuleId]!.hasAccessRuleMatchReasons = true
      } else if (r.category === 'ACCESS_RULE_TARGETING' && accessRuleId && reasonsByAccessRule[accessRuleId]) {
        reasonsByAccessRule[accessRuleId]?.accessRuleTargetingReasons.push(r)
      } else if (['TABLES_MATCH', 'TABLES_TARGETING', 'COMBO_MATCH', 'COMBO_TARGETING'].includes(r.category)) {
        addTableReasonToAccessRuleReasons(r, reasonsByAccessRule, accessRuleIds, getCategoryNameFromReason(r))
      }
    })
  })

  // If we got here and there are no match or targeting reasons for the AR, that means it failed because of table reasons.
  // We should consider that a match failure.
  Object.keys(reasonsByAccessRule).forEach(accessRuleId => {
    if (
      reasonsByAccessRule[accessRuleId] &&
      reasonsByAccessRule[accessRuleId]?.accessRuleMatchReasons.length === 0 &&
      reasonsByAccessRule[accessRuleId]?.accessRuleTargetingReasons.length === 0
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      reasonsByAccessRule[accessRuleId]!.hasAccessRuleMatchReasons = true
    }
  })

  return reasonsByAccessRule
}

function getCategoryNameFromReason(
  reason: AvailabilityDebuggerReason
): 'tablesMatchReasons' | 'tablesTargetingReasons' | 'comboMatchReasons' | 'comboTargetingReasons' {
  switch (reason.category) {
    case 'TABLES_MATCH':
      return 'tablesMatchReasons'
    case 'TABLES_TARGETING':
      return 'tablesTargetingReasons'
    case 'COMBO_MATCH':
      return 'comboMatchReasons'
    case 'COMBO_TARGETING':
      return 'comboTargetingReasons'
    default:
      return 'tablesMatchReasons'
  }
}

function addTableReasonToAccessRuleReasons(
  tableReason: AvailabilityDebuggerReason,
  reasonsByAccessRule: GroupedAvailabilityDebuggerReasonsByAccessRule,
  allAccessRuleIds: string[],
  reasonListName: 'tablesMatchReasons' | 'tablesTargetingReasons' | 'comboMatchReasons' | 'comboTargetingReasons'
) {
  // These aer the only two table reasons that are explicitly tied to an access rule
  if (['INVALID_RULE_FOR_TABLE', 'HELD_BY_ACCESS_RULE'].includes(tableReason.reason) && tableReason.data?.accessRuleId) {
    reasonsByAccessRule[tableReason.data?.accessRuleId]?.[reasonListName].push(tableReason)
  } else {
    allAccessRuleIds.forEach(accessRuleId => {
      reasonsByAccessRule[accessRuleId]?.[reasonListName].push(tableReason)
    })
  }
}
