import { defineMessages } from '@sevenrooms/core/locales'

export const navigationItemMessages = defineMessages({
  legacyReservationSummaryTitle: {
    id: 'reporting.navigationItemMessages.legacyReservationSummaryTitle',
    defaultMessage: 'Reservation Summary',
  },
  legacyReservationSummaryDescription: {
    id: 'reporting.navigationItemMessages.legacyReservationSummaryDescription',
    defaultMessage: 'Overview covers, reservations, walk-ins, no-shows cancellations and spend',
  },
  legacyBookedByTitle: {
    id: 'reporting.navigationItemMessages.legacyBookedByTitle',
    defaultMessage: 'Booked By',
  },
  legacyBookedByDescription: {
    id: 'reporting.navigationItemMessages.legacyBookedByDescription',
    defaultMessage: 'Uncover which channels generate the most spend, covers and reservations',
  },
  legacySourceByTitle: {
    id: 'reporting.navigationItemMessages.legacySourceByTitle',
    defaultMessage: 'Source By',
  },
  legacySourceByDescription: {
    id: 'reporting.navigationItemMessages.legacySourceByDescription',
    defaultMessage: 'Uncover which Sources generate the highest spend, covers and reservations',
  },
  legacyNoShowTitle: {
    id: 'reporting.navigationItemMessages.legacyNoShowTitle',
    defaultMessage: 'No Show / Short Show',
  },
  legacyNoShowDescription: {
    id: 'reporting.navigationItemMessages.legacyNoShowDescription',
    defaultMessage: 'Analyze no-shows and short-shows (reservations that turn up with less than the booked party size)',
  },
  waitTimesTitle: {
    id: 'reporting.navigationItemMessages.waitTimesTitle',
    defaultMessage: 'Wait Times',
  },
  waitTimesDescription: {
    id: 'reporting.navigationItemMessages.waitTimesDescription',
    defaultMessage: 'Discover your waitlist performance',
  },
  turndownTitle: {
    id: 'reporting.navigationItemMessages.turndownTitle',
    defaultMessage: 'Turndown Summary',
  },
  turndownDescription: {
    id: 'reporting.navigationItemMessages.turndownDescription',
    defaultMessage: 'Understand your turnaway volume based on unfufilled Requests and unsat waitlist guests',
  },
  actualsTitle: {
    id: 'reporting.navigationItemMessages.actualsTitle',
    defaultMessage: 'Actuals',
  },
  actualsDescription: {
    id: 'reporting.navigationItemMessages.actualsDescription',
    defaultMessage: 'Examine the financial details of each reservation',
  },
  searchReservationsTitle: {
    id: 'reporting.navigationItemMessages.searchReservationsTitle',
    defaultMessage: 'Search Reservations',
  },
  searchReservationsDescription: {
    id: 'reporting.navigationItemMessages.searchReservationsDescription',
    defaultMessage: 'Comprehensive export of reservation data',
  },
  searchPaymentsTitle: {
    id: 'reporting.navigationItemMessages.searchPaymentsTitle',
    defaultMessage: 'Search Payments',
  },
  searchPaymentsDescription: {
    id: 'reporting.navigationItemMessages.searchPaymentsDescription',
    defaultMessage: 'Comprehensive export of payment data',
  },
  outgoingEmailsTitle: {
    id: 'reporting.navigationItemMessages.outgoingEmailsTitle',
    defaultMessage: 'Outgoing Emails',
  },
  outgoingEmailsDescription: {
    id: 'reporting.navigationItemMessages.outgoingEmailsDescription',
    defaultMessage: 'Audit outgoing email activity',
  },
  activityLogTitle: {
    id: 'reporting.navigationItemMessages.activityLogTitle',
    defaultMessage: 'Activity Log',
  },
  activityLogDescription: {
    id: 'reporting.navigationItemMessages.activityLogDescription',
    defaultMessage: 'Audit activity within your SevenRooms account',
  },
  advertisingTitle: {
    id: 'reporting.navigationItemMessages.advertisingTitle',
    defaultMessage: 'Advertising',
  },
  advertisingDescription: {
    id: 'reporting.navigationItemMessages.advertisingDescription',
    defaultMessage: 'Uncover which campaigns generate the most spend, covers and reservations',
  },
  guestCenterTitle: {
    id: 'reporting.navigationItemMessages.guestCenterTitle',
    defaultMessage: 'GuestCenter Channel Connect',
  },
  guestCenterDescription: {
    id: 'reporting.navigationItemMessages.guestCenterDescription',
    defaultMessage: 'Import reservations from other reservation systems',
  },
  revenueReportTitle: {
    id: 'reporting.navigationItemMessages.revenueReportTitle',
    defaultMessage: 'Revenue Report',
  },
  revenueReportDescription: {
    id: 'reporting.navigationItemMessages.revenueReportDescription',
    defaultMessage:
      'See exactly how much revenue you’re generating with SevenRooms, compare it to competitors, and identify the best tools to maximize returns with SevenRooms',
  },
})
