import { useMemo, useRef } from 'react'
import { useGetAudienceHierarchyQuery } from '@sevenrooms/core/api'
import type { AvailabilityDebuggerReason, Venue, AudienceHierarchy, AvailabilityDebuggerReasonAccessRule } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { DateOnly, TimeOnly } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { useTheme } from '@sevenrooms/react-components/hooks/useTheme'
import { accessRulesMatchCategoryMessages, accessRulesTargetingCategoryMessages } from '../../locales'
import { AccessRulesMatchCategory } from './ContentCategory/AccessRulesMatchCategory'
import { AccessRulesTargetingCategory } from './ContentCategory/AccessRulesTargetingCategory'
import { InactiveCategory } from './ContentCategory/InactiveCategory'
import { groupReasonsByAccessRule, groupReasonsByCategory } from './ContentCategory/reasonGroupingUtil'
import { ShiftsCategory } from './ContentCategory/ShiftsCategory'
import { VenueCategory } from './ContentCategory/VenueCategory'

export interface ModalContentProps {
  allReasons: AvailabilityDebuggerReason[]
  allAccessRules: AvailabilityDebuggerReasonAccessRule[]
  venue: Venue
  date: DateOnly
  time: TimeOnly
  covers: number
  duration: number
}

export function ReasonsModalContent({ allReasons, allAccessRules, venue, date, time, covers, duration }: ModalContentProps) {
  const { formatMessage } = useLocales()
  const theme = useTheme()
  const firstCategoryRender = useRef(true)
  const { isFetching, data: audienceHierarchy } = useGetAudienceHierarchyQuery({ venueId: venue.id })

  const audiences = useMemo(() => {
    const result: Record<string, string> = {}

    if (isFetching || !audienceHierarchy) {
      return null
    }

    function recurse(audienceHierarchies: AudienceHierarchy[]) {
      for (const item of audienceHierarchies) {
        if (item.name && item.value) {
          result[item.value] = item.name
        }
        if (item.children && item.children.length > 0) {
          recurse(item.children)
        }
      }
    }

    recurse(audienceHierarchy)
    return result
  }, [audienceHierarchy, isFetching])

  const groupByCategories = useMemo(() => groupReasonsByCategory(allReasons), [allReasons])
  const groupByAccessRules = useMemo(() => groupReasonsByAccessRule(allReasons, allAccessRules), [allReasons, allAccessRules])
  const accessRulesWithMatchReasons = useMemo(() => {
    if (!groupByAccessRules) {
      return null
    }
    const accessRules = Object.values(groupByAccessRules).filter(accessRuleReasons => accessRuleReasons.hasAccessRuleMatchReasons)
    return accessRules.sort((a, b) => a.accessRuleName.localeCompare(b.accessRuleName))
  }, [groupByAccessRules])
  const accessRulesWithoutMatchReasons = useMemo(() => {
    if (!groupByAccessRules) {
      return null
    }
    const accessRules = Object.values(groupByAccessRules).filter(accessRuleReasons => !accessRuleReasons.hasAccessRuleMatchReasons)
    return accessRules.sort((a, b) => a.accessRuleName.localeCompare(b.accessRuleName))
  }, [groupByAccessRules])

  if (!groupByCategories || !audiences) {
    return <></>
  }

  const hasHardStopReasons = Object.entries(groupByCategories).some(
    ([category, reasons]) => ['INACTIVE', 'VENUE', 'SHIFTS'].includes(category) && reasons.length > 0
  )

  return (
    <Box data-test="reasons-modal-content" sx={{ overflow: 'auto', paddingLeft: 4, paddingRight: 4, paddingBottom: 4 }}>
      {Object.entries(groupByCategories)
        .filter(([, reasons]: [string, AvailabilityDebuggerReason[]]) => reasons.length > 0)
        .map(([category, reasons]) => {
          const key = `${category}-${reasons[0]?.reason}`
          switch (category) {
            case 'INACTIVE':
              return <InactiveCategory key={key} reasons={reasons} venue={venue} time={time} />
            case 'VENUE':
              return <VenueCategory key={key} venue={venue} reasons={reasons} />
            case 'SHIFTS':
              return <ShiftsCategory key={key} reasons={reasons} venue={venue} time={time} date={date} covers={covers} />
            default:
              return <></>
          }
        })}
      {!hasHardStopReasons && (
        <>
          {accessRulesWithMatchReasons?.length ? (
            <Stack direction="column" display="flex" spacing={4}>
              <Typography variant="h5">
                {formatMessage(accessRulesMatchCategoryMessages.subtitle, { count: accessRulesWithMatchReasons.length })}
              </Typography>
              {accessRulesWithMatchReasons.map(accessRule => {
                const isFirst = firstCategoryRender.current
                if (isFirst) {
                  firstCategoryRender.current = false
                }
                return (
                  <AccessRulesMatchCategory
                    key={accessRule.accessRuleId}
                    accessRuleReasonData={accessRule}
                    covers={covers}
                    date={date}
                    time={time}
                    venue={venue}
                    audiences={audiences}
                    defaultExpanded={isFirst}
                  />
                )
              })}
            </Stack>
          ) : null}
          {accessRulesWithMatchReasons?.length && accessRulesWithoutMatchReasons?.length ? (
            <Box
              sx={{
                mt: 4,
                mb: 4,
                width: '100%',
                borderBottom: 'solid',
                borderBottomColor: theme.palette.grey[200],
                borderBottomWidth: '1px',
              }}
            />
          ) : null}
          {accessRulesWithoutMatchReasons?.length ? (
            <Stack direction="column" display="flex" spacing={4}>
              <Typography variant="h5">
                {formatMessage(accessRulesTargetingCategoryMessages.subtitle, { count: accessRulesWithoutMatchReasons.length })}
              </Typography>
              {accessRulesWithoutMatchReasons.map(accessRule => {
                const isFirst = firstCategoryRender.current
                if (isFirst) {
                  firstCategoryRender.current = false
                }
                return (
                  <AccessRulesTargetingCategory
                    key={accessRule.accessRuleId}
                    accessRuleReasonData={accessRule}
                    date={date}
                    time={time}
                    venue={venue}
                    duration={duration}
                    defaultExpanded={isFirst}
                  />
                )
              })}
            </Stack>
          ) : null}
        </>
      )}
    </Box>
  )
}
