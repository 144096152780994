import type { AvailabilityDebuggerReason, Venue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { TimeOnly, DateOnly } from '@sevenrooms/core/timepiece'
import { Button } from '@sevenrooms/react-components/components/Button'
import { Link } from '@sevenrooms/react-components/components/Link'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { shiftsCategoryMessages } from '../../../locales'
import { NonexpandableCard } from '../Cards/NonexpandableCard'

interface ShiftsContentCategoryProps {
  reasons: AvailabilityDebuggerReason[]
  venue: Venue
  time: TimeOnly
  date: DateOnly
  covers: number
}

export function ShiftsCategory({ reasons, venue, time, date, covers }: ShiftsContentCategoryProps) {
  const { formatMessage } = useLocales()

  // This category is a full stop category, so we are guaranteed to only have one element in the array
  const shiftReason = reasons[0]

  switch (shiftReason?.reason) {
    case 'NO_MATCHING_SHIFTS':
    case 'NO_SHIFTS_FOUND':
      return (
        <NonexpandableCard
          data-test="shifts-category-card"
          title={formatMessage(shiftsCategoryMessages.mainDescriptionNoShiftsFound, { time: time.formatSTime() })}
          action={
            <Button
              data-test="no-shifts-found-button"
              size="small"
              variant="contained"
              onClick={() => window.open(`/manager/${venue.urlKey}/manage/capacity/schedule`, '_blank')}
            >
              {formatMessage(shiftsCategoryMessages.manageShiftsNoShiftsFound)}
            </Button>
          }
        />
      )
    case 'OVER_MAX_SHIFT_COVERS':
      return (
        <NonexpandableCard
          data-test="shifts-category-card"
          title={formatMessage(shiftsCategoryMessages.mainDescriptionHeaderOverMaxShift, {
            shiftName: shiftReason.data?.shiftName as string,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            a: (chunks: string[]) => (
              <Link
                data-test="over-max-shift-link"
                href={`/manager/${venue.urlKey}/manage/capacity/schedule?selected_shift_persistent_id=${
                  shiftReason.data?.shiftPersistentId
                }&date=${date.toJsDate().toLocaleString()}`}
                target="_blank"
              >
                {chunks}
              </Link>
            ),
          })}
        >
          <Typography>
            {formatMessage(shiftsCategoryMessages.mainDescriptionOverMaxShift, {
              count: covers,
              covers,
              time: time.formatSTime(),
              bookedTotalPossibleCovers: shiftReason.data?.bookedTotalPossibleCovers,
              coversMaxPerShift: shiftReason.data?.coversMaxPerShift,
            })}
          </Typography>
        </NonexpandableCard>
      )
    default:
      return null
  }
}
