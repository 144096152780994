import { defineMessages } from '@sevenrooms/core/locales'

export const reasonsModalMessages = defineMessages({
  titleDate: {
    id: 'reasonsModal.titleInfo',
    defaultMessage: `{count, plural,
      one {{covers} cover at {time}}
      other {{covers} covers at {time}}
    }`,
  },
  tables: {
    id: 'reasonsModal.accessRulesMatchCategory.tables',
    defaultMessage: 'Tables',
  },
  tableCombinations: {
    id: 'reasonsModal.accessRulesMatchCategory.tableCombinations',
    defaultMessage: 'Table Combinations',
  },
  manageTablesButtonText: {
    id: 'reasonsModal.tablesMatchCategory.manageTablesButtonText',
    defaultMessage: 'Manage Tables',
  },
  manageTableCombosButtonText: {
    id: 'reasonsModal.combosMatchCategory.manageCombinationsButtonText',
    defaultMessage: 'Manage Table Combinations',
  },
} as const)

export const inactiveCategoryMessages = defineMessages({
  mainDescriptionNoShift: {
    id: 'reasonsModal.inactiveCategory.noShift.mainDescription',
    defaultMessage: 'There are no Shifts at {time}.',
  },
  noShiftButtonText: {
    id: 'reasonsModal.inactiveCategory.noShift.buttonText',
    defaultMessage: 'Manage Shifts',
  },
  mainDescriptionPastTime: {
    id: 'reasonsModal.inactiveCategory.pastTime.mainDescription',
    defaultMessage: 'Time slot is in the past.',
  },
} as const)

export const venueCategoryMessages = defineMessages({
  mainDescriptionClosed: {
    id: 'reasonsModal.venueCategory.mainDescriptionClosed',
    defaultMessage: 'A Blackout Date is in place.',
  },
  mainDescriptionNoAccessRules: {
    id: 'reasonsModal.venueCategory.mainDescriptionNoAccessRules',
    defaultMessage: 'No Access Rules for this Shift',
  },
  manageBlackoutDates: {
    id: 'reasonsModal.venueCategory.manageBlackoutDate',
    defaultMessage: 'Manage Blackout Dates',
  },
  manageAccessRules: {
    id: 'reasonsModal.venueCategory.manageAccessRules',
    defaultMessage: 'Manage Access Rules',
  },
} as const)

export const shiftsCategoryMessages = defineMessages({
  mainDescriptionNoShiftsFound: {
    id: 'reasonsModal.shiftsCategory.noShiftsFound.mainDescription',
    defaultMessage: 'There are no Shifts at {time}.',
  },
  manageShiftsNoShiftsFound: {
    id: 'reasonsModal.shiftsCategory.noShiftsFound.manageShifts',
    defaultMessage: 'Manage Shifts',
  },
  mainDescriptionHeaderOverMaxShift: {
    id: 'reasonsModal.shiftsCategory.overMaxShift.mainDescriptionHeader',
    defaultMessage: 'Shift: <a>{shiftName}</a>',
  },
  mainDescriptionOverMaxShift: {
    id: 'reasonsModal.shiftsCategory.overMaxShift.mainDescription',
    defaultMessage: `{count, plural,
      one {Booking {covers} cover at {time} exceeds the maximum total covers for the Shift.
      ({bookedTotalPossibleCovers} of {coversMaxPerShift} covers are booked.)}
      other {Booking {covers} covers at {time} exceeds the maximum total covers for the Shift.
      ({bookedTotalPossibleCovers} of {coversMaxPerShift} covers are booked.)}
    }`,
  },
} as const)

export const accessRulesMatchCategoryMessages = defineMessages({
  subtitle: {
    id: 'reasonsModal.accessRulesMatchCategory.subtitle',
    defaultMessage: `{count, plural,
      one {{count} Access Rule matches search criteria but has other restrictions}
      other {{count} Access Rules match search criteria but have other restrictions}
    }`,
  },
  noTablesAvailable: {
    id: 'reasonsModal.accessRulesMatchCategory.noTablesAvailable.mainDescription',
    defaultMessage:
      'The Access Rule does not have any available Tables or Table Combinations for this Party Size. Explore specific reasons below.',
  },
  mainDescriptionHeader: {
    id: 'reasonsModal.accessRulesMatchCategory.mainDescriptionHeader',
    defaultMessage: 'Access Rule: <a>{ruleName}</a>',
  },
  mainDescriptionNoInventoryReservations: {
    id: 'reasonsModal.accessRulesMatchCategory.noInventoryReservations.mainDescription',
    defaultMessage: 'Booking a reservation at {time} exceeds the Reservation Limit for this Access Rule.',
  },
  mainDescriptionNoInventoryCovers: {
    id: 'reasonsModal.accessRulesMatchCategory.noInventoryCovers.mainDescription',
    defaultMessage: `{count, plural,
      one {Booking {count} cover at {time} exceeds the Access Rule Pacing.}
      other {Booking {count} covers at {time} exceeds the Access Rule Pacing.}
    }`,
  },
  mainDescriptionCutoffPassed: {
    id: 'reasonsModal.accessRulesMatchCategory.cutoffPassed.mainDescription',
    defaultMessage: `The Guest Booking Cutoff Time for this Access Rule requires Reservations to be made {cutoffNum} {cutoffType, select,
      MINUTES {{cutoffNum, plural,
        one {minute}
        other {minutes}
      }}
      HOURS {{cutoffNum, plural,
        one {hour}
        other {hours}
      }}
      DAYS {{cutoffNum, plural,
        one {day}
        other {days}
      }}
      WEEKS {{cutoffNum, plural,
        one {week}
        other {weeks}
      }}
      MONTHS {{cutoffNum, plural,
        one {month}
        other {months}
      }}
      other {{cutoffType}}
    } before {cutoffHour, select,
      RESERVATION_TIME {the Reservation Time}
      other {{cutoffHour}}
    }.`,
  },
  mainDescriptionNotReachedBookingStartTime: {
    id: 'reasonsModal.accessRulesMatchCategory.notReachedBookingStartTime.mainDescription',
    defaultMessage: `The Guest Booking Start Time ({startNum} {startType, select,
      MINUTES {{startNum, plural,
        one {minute}
        other {minutes}
      }}
      HOURS {{startNum, plural,
        one {hour}
        other {hours}
      }}
      DAYS {{startNum, plural,
        one {day}
        other {days}
      }}
      WEEKS {{startNum, plural,
        one {week}
        other {weeks}
      }}
      MONTHS {{startNum, plural,
        one {month}
        other {months}
      }}
      other {{startType}}
    } before {startHour, select,
      RESERVATION_TIME {Reservation time}
      other {{startHour}}
    }) for the "{audience}" Audience is in the future.`,
  },
  mainDescriptionNoPacingCoversRemaining: {
    id: 'reasonsModal.accessRulesMatchCategory.noPacingCoversRemaining.mainDescription',
    defaultMessage: `{count, plural,
      one {Booking {covers} cover at {time} exceeds the Access Rule Pacing.}
      other {Booking {covers} covers at {time} exceeds the Access Rule Pacing.}
    }`,
  },
  mainDescriptionTagsMismatch: {
    id: 'reasonsModal.accessRulesMatchCategory.tagsMismatch.mainDescription',
    defaultMessage: 'The Access Rule Client Tags for this Audience ({audience}) do not match',
  },
})

export const accessRulesTargetingCategoryMessages = defineMessages({
  subtitle: {
    id: 'reasonsModal.accessRulesTargetingCategory.subtitle',
    defaultMessage: `{count, plural,
      one {{count} Access Rule does not match search criteria}
      other {{count} Access Rules do not match search criteria}
    }`,
  },
  mainDescriptionHeader: {
    id: 'reasonsModal.accessTargetingCategory.mainDescriptionHeader',
    defaultMessage: 'Access Rule: <a>{ruleName}</a>',
  },
  mainDescriptionPartySizeRestrictionMismatch: {
    id: 'reasonsModal.accessRulesTargetingCategory.partySizeRestrictionMismatch.mainDescription',
    defaultMessage: "The Access Rule's Party Size ({partyMin}-{partyMax}) does not include the selected Party Size.",
  },
  mainDescriptionAudienceTierMismatch: {
    id: 'reasonsModal.accessRulesTargetingCategory.audienceTierMismatch.mainDescription',
    defaultMessage:
      'The Access Rule is either not available for the selected Audience or the selected date is not within the Booking Window.',
  },
  mainDescriptionDurationRestrictionMismatch: {
    id: 'reasonsModal.accessRulesTargetingCategory.durationRestrictionMismatch.mainDescription',
    defaultMessage: 'The Access Rule Guest Duration Picker does not match the searched Duration Picker value.',
  },
  mainDescriptionNotAllowedTime: {
    id: 'reasonsModal.accessRulesTargetingCategory.notAllowedTime.mainDescription',
    defaultMessage: "The Access Rule's time slots ({arTimes}) do not include the selected time slot ({time}).",
  },
  mainDescriptionNotWithinTimeRange: {
    id: 'reasonsModal.accessRulesTargetingCategory.notWithinTimeRange.mainDescription',
    defaultMessage:
      "The Access Rule's time range ({arTimeRangeStart} - {arTimeRangeEnd}) does not include the selected time slot ({time}).",
  },
  mainDescriptionNotDurationPickerEligible: {
    id: 'reasonsModal.accessRulesTargetingCategory.notDurationPickerEligible.mainDescription',
    defaultMessage: 'The Access Rule does not have Guest Duration Picker enabled.',
  },
  mainDescriptionOnlyDurationPickerEligible: {
    id: 'reasonsModal.accessRulesTargetingCategory.onlyDurationPickerEligible.mainDescription',
    defaultMessage: 'The Access Rule has Guest Duration Picker enabled.',
  },
  mainDescriptionHeldByAccessRule: {
    id: 'reasonsModal.accessRulesMatchCategory.heldByAccessRule.mainDescription',
    defaultMessage: '"{ruleName}" restricts Table from being booked outside of that Access Rule.',
  },
  mainDescriptionPrivateEventsTableRestriction: {
    id: 'reasonsModal.accessRulesTargetingCategory.privateEventsTableRestriction.mainDescription',
    defaultMessage: 'Table is only valid for Private Events Access Rules',
  },
  mainDescriptionPrivateEventsTableComboRestriction: {
    id: 'reasonsModal.accessRulesTargetingCategory.privateEventsTableComboRestriction.mainDescription',
    defaultMessage: 'Table Combination is only valid for Private Events Access Rules',
  },
})

export const tablesCategoryMessages = defineMessages({
  noSeatingArea: {
    id: 'reasonsModal.tablesCategory.noSeatingArea',
    defaultMessage: 'No Seating Area',
  },
  hardAssignedReservations: {
    id: 'reasonsModal.tablesCategory.hardAssignedReservations',
    defaultMessage: 'Table {table} ({seatingAreaName}) overlapping Hard-Assigned <a>Reservation</a>',
  },
  autoAssignedReservations: {
    id: 'reasonsModal.tablesCategory.autoAssignedReservations',
    defaultMessage: 'Table {table} ({seatingAreaName}) overlapping Auto-Assigned <a>Reservation</a>',
  },
  startTimeBlock: {
    id: 'reasonsModal.tablesCategory.startTimeBlock',
    defaultMessage: 'Table {table} ({seatingAreaName}) Start-time-only Blocked (<a>{blockName}</a>)',
  },
  blocked: {
    id: 'reasonsModal.tablesCategory.blocked',
    defaultMessage: 'Table {table} ({seatingAreaName}) Blocked (<a>{blockName}</a>)',
  },
  noTables: {
    id: 'reasonsModal.tablesCategory.noTables',
    defaultMessage: 'Table {table} ({seatingAreaName}) not included in Shift Seating Area and Floorplan Layout',
  },
  notFoundInShiftLayout: {
    id: 'reasonsModal.tablesCategory.notFoundInShiftLayout',
    defaultMessage: 'Table {table} ({seatingAreaName}) not included in Shift Floorplan Layout',
  },
  notInSeatingArea: {
    id: 'reasonsModal.tablesCategory.notInSeatingArea',
    defaultMessage: 'Table {table} ({seatingAreaName}) not included in Shift Seating Area',
  },
  invalidRuleForTable: {
    id: 'reasonsModal.tablesCategory.invalidRuleForTable',
    defaultMessage: 'Table {table} ({seatingAreaName}) not included in the Access Rule Seating Areas',
  },
  partySizeMismatch: {
    id: 'reasonsModal.tablesCategory.partySizeMismatch',
    defaultMessage: 'Table {table} ({seatingAreaName}) Party Size Min-Max ({min}-{max}) does not match the selected Party Size',
  },
  heldByAccessRule: {
    id: 'reasonsModal.tablesCategory.heldByAccessRule',
    defaultMessage: 'Table {table} ({seatingAreaName}) restricted from being booked by another Access Rule',
  },
})

export const tableCombosCategoryMessages = defineMessages({
  startTimeBlock: {
    id: 'reasonsModal.tableCombosCategory.startTimeBlock',
    defaultMessage:
      'Table {combo} one or more Tables in this Combination are unavailable due to Start-time-only Block (<a>{blockName}</a>)',
  },
  blockedAtTime: {
    id: 'reasonsModal.tableCombosCategory.blockedAtTime',
    defaultMessage: 'Table {combo} one or more Tables in this Combination are unavailable due to Block (<a>{blockName}</a>)',
  },
  notAllAvailable: {
    id: 'reasonsModal.tableCombosCategory.notAllAvailable',
    defaultMessage: 'Table {combo} one or more Tables in this Combination are not included in the Shift Seating Area',
  },
  partySizeMismatch: {
    id: 'reasonsModal.tableCombosCategory.partySizeMismatch',
    defaultMessage: 'Table {combo} Party Size Min-Max ({min}-{max}) does not match the selected Party Size',
  },
  tableComboNotAllowed: {
    id: 'reasonsModal.combosTargetingCategory.tableComboNotAllowed',
    defaultMessage: 'Table {combo} only valid for Private Events Access Rules',
  },
  heldByAccessRule: {
    id: 'reasonsModal.tablesCategory.heldByAccessRule',
    defaultMessage: 'Table {combo} restricted from being booked by another Access Rule',
  },
})
