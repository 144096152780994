import { defineMessages } from '@sevenrooms/core/locales'

export const calendarMessages = defineMessages({
  listHeaderVenue: {
    id: 'calendar.listHeader',
    defaultMessage: 'Venue',
  },
  listHeaderSpace: {
    id: 'calendar.listHeaderSpace',
    defaultMessage: 'Space',
  },
  listHeaderTime: {
    id: 'calendar.listHeaderTime',
    defaultMessage: 'Time',
  },
  listHeaderCovers: {
    id: 'calendar.listHeaderCovers',
    defaultMessage: 'Covers',
  },
  listHeaderEventName: {
    id: 'calendar.listHeaderEventName',
    defaultMessage: 'Event name',
  },
  listHeaderPhone: {
    id: 'calendar.listHeaderPhone',
    defaultMessage: 'Phone',
  },
  listHeaderTables: {
    id: 'calendar.listHeaderTables',
    defaultMessage: 'Tables',
  },
  listHeaderStatus: {
    id: 'calendar.listHeaderStatus',
    defaultMessage: 'Status',
  },
  guests: {
    id: 'calendar.guests',
    defaultMessage: 'guests',
  },
} as const)
