import { useLocales } from '@sevenrooms/core/locales'
import { Route, Switch, useLocation } from '@sevenrooms/core/navigation'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPage, SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
// eslint-disable-next-line import/no-relative-packages
import { ErrorNotFound } from '../../../../../application/site/static/app/manager/pages/src/default'
// eslint-disable-next-line import/no-relative-packages
import LookerReportingEmbed from '../../../../../application/site/static/app/manager/pages/src/shared/components/reporting/LookerReportingEmbed'
import ribbon from '../../assets/ribbon.png'
import { InsightsDashboard, InsightDetailSlideout } from '../../components'
import { InsightsDashboardContext, useInsightsDashboardContext } from '../../hooks/useInsightsDashboardContext'
import { useNavigationItems } from '../../hooks/useNavigationItems'
import { insightsMessages } from '../../locales'

interface InsightsContentProps {
  isShowingSideNavigation: boolean
}

function InsightsDashboardContent({ isShowingSideNavigation }: InsightsContentProps) {
  const { formatMessage } = useLocales()

  const background = `no-repeat url(${ribbon}) calc(100% + 160px) -469px / 940px`

  return (
    <SettingsPageContent
      secondHeaderMaxWidth="70%"
      secondHeaderTextMaxWidth="100%"
      title={formatMessage(insightsMessages.insightsTitle)}
      description={
        <Box mr="4xl" pr="3xl">
          {formatMessage(insightsMessages.insightsDescription)}
        </Box>
      }
      isShowingSideNavigation={isShowingSideNavigation}
      headerBackground={background}
    >
      <InsightsDashboard />
    </SettingsPageContent>
  )
}

function LookerReportingEmbedContent({ isShowingSideNavigation }: InsightsContentProps) {
  const location = useLocation()
  const url = location.pathname.slice(location.pathname.indexOf('embed/'))
  const { setSlideoutOpen } = useInsightsDashboardContext()
  setSlideoutOpen(false)

  return (
    <SettingsPageContent isShowingSideNavigation={isShowingSideNavigation}>
      <LookerReportingEmbed isGroup={false} url={url} />
    </SettingsPageContent>
  )
}

export function Insights() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { navigationItems, reportCount } = useNavigationItems()

  if (!venue) {
    return null
  }

  const showReports = reportCount > 0

  return (
    <InsightsDashboardContext>
      <SettingsPage hideSearch showSideNavigation={showReports} sideNavigationItems={navigationItems}>
        <SettingsPageMeta title={formatMessage(insightsMessages.insightsTitle)} />
        <Switch>
          <Route exact path={routes.manager2.insights.path}>
            <InsightsDashboardContent isShowingSideNavigation={showReports} />
          </Route>
          {showReports && (
            <Route path={routes.manager2.insights.embed.path}>
              <LookerReportingEmbedContent isShowingSideNavigation={showReports} />
            </Route>
          )}
          <Route path="*" component={ErrorNotFound} />
        </Switch>
      </SettingsPage>
      <InsightDetailSlideout />
    </InsightsDashboardContext>
  )
}
