import { type PropsWithChildren, useState } from 'react'
import { Card } from '@sevenrooms/react-components/components/Card'
import { CardContent } from '@sevenrooms/react-components/components/CardContent'
import { CardHeader } from '@sevenrooms/react-components/components/CardHeader'
import { Collapse } from '@sevenrooms/react-components/components/Collapse'
import { ExpandMore } from './ExpandMore'
import type { CardProps } from './CardProps'

export function NestedCard({ title, action, startExpanded, children }: PropsWithChildren<CardProps>) {
  const [expanded, setExpanded] = useState(Boolean(startExpanded))
  return (
    <Card sx={{ border: '0', flexDirection: 'row-reverse' }}>
      <CardHeader
        title={
          <>
            <ExpandMore expand={expanded} onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-label="show more" />
            {title}
          </>
        }
        action={action}
        sx={{ pb: 0 }}
      />

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ pb: 0, ml: 10 }}>{children}</CardContent>
      </Collapse>
    </Card>
  )
}
