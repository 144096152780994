import { type PropsWithChildren, useState } from 'react'
import { Card } from '@sevenrooms/react-components/components/Card'
import { CardContent } from '@sevenrooms/react-components/components/CardContent'
import { CardHeader } from '@sevenrooms/react-components/components/CardHeader'
import { Collapse } from '@sevenrooms/react-components/components/Collapse'
import { ExpandMore } from './ExpandMore'
import type { CardProps } from './CardProps'

export function ExpandableCard({ title, startExpanded, children }: PropsWithChildren<CardProps>) {
  const [expanded, setExpanded] = useState(Boolean(startExpanded))

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Card>
      <CardHeader
        title={title}
        action={<ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more" />}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>{children}</CardContent>
      </Collapse>
    </Card>
  )
}
