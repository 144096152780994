import { useLocales } from '@sevenrooms/core/locales'
import { Route, Switch } from '@sevenrooms/core/navigation'
import { SettingsPage, SettingsPageContent, SettingsPageMeta } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { Calendar } from '../../components'
import { PrivateEventsContext, useNavigationItems } from '../../hooks'
import { privateEventsMessages } from '../../locales'

export function PrivateEvents() {
  const { navigationItems } = useNavigationItems()
  const { formatMessage } = useLocales()

  return (
    <PrivateEventsContext>
      <SettingsPage hideSearch showSideNavigation sideNavigationItems={navigationItems}>
        <SettingsPageMeta title={formatMessage(privateEventsMessages.pageTitle)} />
        <Switch>
          <Route exact path={routes.manager2.privateEvents.calendar.path}>
            <SettingsPageContent isShowingSideNavigation>
              <Calendar />
            </SettingsPageContent>
          </Route>
        </Switch>
      </SettingsPage>
    </PrivateEventsContext>
  )
}
