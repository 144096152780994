import { Card } from '@sevenrooms/react-components/components/Card'
import { CardContent } from '@sevenrooms/react-components/components/CardContent'
import { CardHeader } from '@sevenrooms/react-components/components/CardHeader'
import type { CardProps } from './CardProps'
import type { PropsWithChildren } from 'react'

export function NonexpandableCard({ title, action, children }: PropsWithChildren<CardProps>) {
  return (
    <Card>
      {title || action ? <CardHeader title={title} action={action} /> : null}
      {children ? <CardContent>{children}</CardContent> : null}
    </Card>
  )
}
