import React, { useCallback, useMemo, useState } from 'react'
import {
  useGetAccessRulesListQuery,
  useGetAudienceHierarchyQuery,
  useGetAvailabilityBookedCoversQuery,
  useGetAvailabilityCalendarQuery,
  useGetSeatingAreasTablesQueryNew,
} from '@sevenrooms/core/api'
import type { AvailabilityCalendarPayload } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { getDateFNSLocale } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPage, SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { useNavigationItems } from '@sevenrooms/mgr-settings/Settings/hooks'
import { Box as RCBox } from '@sevenrooms/react-components/components/Box'
import { Button } from '@sevenrooms/react-components/components/Button'
import { Link } from '@sevenrooms/react-components/components/Link'
import { LocalizationProvider } from '@sevenrooms/react-components/components/LocalizationProvider'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { TablePagination } from '@sevenrooms/react-components/components/TablePagination/index'
import { ThemeProvider, vmsTheme } from '@sevenrooms/react-components/components/ThemeProvider'
import { AvailabilityCalendarGrid } from '../../components/AvailabilityCalendarGrid'
import { AvailabilityCalendarLoader } from '../../components/AvailabilityCalendarGrid/AvailabilityCalendarLoader'
import { GRID_COVERS_DEFAULT } from '../../components/AvailabilityCalendarGrid/constants'
import { AvailabilityCalendarInput } from '../../components/AvailabilityCalendarInput'
import { AvailabilityCovers } from '../../components/AvailabilityCovers'
import { AvailabilityDebuggerProvider } from '../../contexts'
import { useAvailabilityUrlParams, useDateParam } from '../../hooks'
import { availabilityDebuggerMessages } from '../../locales'

export function AvailabilityDebugger() {
  const { venueId } = useVenueContext()
  const { formatMessage } = useLocales()
  const { venueLocale, venueUrlKey } = useAppContext()
  const sideNavigationItems = useNavigationItems()
  const locale = getDateFNSLocale(venueLocale)

  const date = useDateParam()

  const accessRules = useGetAccessRulesListQuery({ venueId, startDate: date.toIso() })
  const audienceHierarchy = useGetAudienceHierarchyQuery({ venueId })
  const seatingAreasTables = useGetSeatingAreasTablesQueryNew({ venueId })
  const shiftCovers = useGetAvailabilityBookedCoversQuery({ venueId, date })

  const isFetching = audienceHierarchy.isFetching || accessRules.isFetching || seatingAreasTables.isFetching || shiftCovers.isFetching

  return (
    // This outer box with width=100% is necessary for the horizontal scroll inside the availability calendar grid.
    // SettingsPageLayout does not have width=100% unless you enable side navigation
    <Box width="100%" backgroundColor="primaryBackground">
      <ThemeProvider theme={vmsTheme}>
        <LocalizationProvider adapterLocale={locale}>
          <SettingsPage showSideNavigation sideNavigationItems={sideNavigationItems}>
            <SettingsPageMeta title={formatMessage(availabilityDebuggerMessages.title)} />
            <SettingsPageContent
              secondHeaderMaxWidth="100%"
              secondHeaderTextMaxWidth="100%"
              title={formatMessage(availabilityDebuggerMessages.title)}
              description={formatMessage(availabilityDebuggerMessages.subtitle, {
                a: (chunks: string[]) => (
                  <Link
                    data-test="availability-quick-view-help"
                    href="/help?return_to=/hc/en-us/articles/26560815768859-Availability-Quick-View"
                  >
                    {chunks}
                  </Link>
                ),
              })}
              actions={
                <Button
                  variant="contained"
                  onClick={() => window.open(`${window.location.origin}/reservations/${venueUrlKey}`, '_blank')}
                  data-test="view-reservation-widget-button"
                >
                  {formatMessage(availabilityDebuggerMessages.viewReservationWidgetButton)}
                </Button>
              }
            >
              <Box backgroundColor="primaryBackground" width="100%" height="100%" p="l" display="flex">
                {isFetching || accessRules.data == null || shiftCovers.data == null ? (
                  <RCBox sx={{ mx: 'auto' }}>
                    <AvailabilityCalendarLoader minHeight={600} />
                  </RCBox>
                ) : (
                  <>
                    <AvailabilityDebuggerProvider value={{ accessRules: accessRules.data }}>
                      <AvailabilityDebuggerHydrated />
                    </AvailabilityDebuggerProvider>
                    <AvailabilityCovers shiftCovers={shiftCovers.data} />
                  </>
                )}
              </Box>
            </SettingsPageContent>
          </SettingsPage>
        </LocalizationProvider>
      </ThemeProvider>
    </Box>
  )
}

export function AvailabilityDebuggerHydrated() {
  const { venue } = useVenueContext()

  const [urlparams] = useAvailabilityUrlParams()

  const [coversInterval, setCoversInterval] = useState<number>(GRID_COVERS_DEFAULT)
  const [partySizeMax, setPartySizeMax] = useState<number>(GRID_COVERS_DEFAULT)
  const partySizeNextLoadStartOffset = useMemo(() => partySizeMax - coversInterval, [coversInterval, partySizeMax])

  const partySizes = useMemo(() => Array.from({ length: partySizeMax }, (_, i) => i + 1), [partySizeMax])

  const query: AvailabilityCalendarPayload = {
    venueId: venue.id,
    partySizes: partySizes.slice(partySizeNextLoadStartOffset, partySizeMax),
    ...urlparams,
  }

  const availabilityCalendarData = useGetAvailabilityCalendarQuery(query)

  const handleLoadMoreCovers = useCallback(
    (isPrevious = false) => {
      setPartySizeMax(prev => (isPrevious ? prev - coversInterval : prev + coversInterval))
    },
    [coversInterval]
  )

  const handleCoversPerPageChange = (val: number) => {
    setCoversInterval(val)
    setPartySizeMax(val)
  }

  if (!venue || availabilityCalendarData.isError) {
    return null
  }

  return (
    <>
      <Stack direction="column" spacing={2} sx={{ height: '100%', width: '100%' }}>
        <Box width="100%">
          <AvailabilityCalendarInput />
        </Box>
        <Box width="100%" height="100%" maxHeight="calc(100vh - 375px)" minHeight="15rem">
          <>
            <AvailabilityCalendarGrid
              isFetching={availabilityCalendarData.isFetching || !availabilityCalendarData.data}
              data={availabilityCalendarData?.data}
              date={urlparams.date}
              coversPerPage={coversInterval}
            />
            <CoversPagination
              handleLoadMoreCovers={handleLoadMoreCovers}
              handleCoversPerPageChange={handleCoversPerPageChange}
              coversPerPage={coversInterval}
            />
          </>
        </Box>
      </Stack>
    </>
  )
}

const CoversPagination = React.memo(
  ({
    handleLoadMoreCovers,
    handleCoversPerPageChange,
    coversPerPage,
  }: {
    handleLoadMoreCovers: (isPrevious?: boolean) => void
    handleCoversPerPageChange: (val: number) => void
    coversPerPage: number
  }) => {
    const { formatMessage } = useLocales()
    const [page, setPage] = React.useState(0)

    const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      if (newPage > page) {
        handleLoadMoreCovers(false)
      } else {
        handleLoadMoreCovers(true)
      }
      setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleCoversPerPageChange(parseInt(event.target.value, 10))
      setPage(0)
    }

    return (
      <TablePagination
        data-test="covers-pagination"
        slotProps={{
          select: {
            id: 'table-pagination-select',
            name: 'table-pagination-select',
          },
        }}
        component="div"
        count={-1}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[15, 20, 25]}
        rowsPerPage={coversPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to }) => formatMessage(availabilityDebuggerMessages.labelDisplayedRows, { from, to })}
        labelRowsPerPage={formatMessage(availabilityDebuggerMessages.labelRowsPerPage)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '&.MuiTablePagination-root p': {
            margin: 0,
          },
          '& .MuiTablePagination-displayedRows': {
            width: '84px',
          },
        }}
      />
    )
  },
  (prevProps, nextProps) =>
    prevProps.coversPerPage === nextProps.coversPerPage &&
    prevProps.handleLoadMoreCovers === nextProps.handleLoadMoreCovers &&
    prevProps.handleCoversPerPageChange === nextProps.handleCoversPerPageChange
)
