import type { AvailabilityDebuggerReason, Venue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { type DateOnly, TimeOnly } from '@sevenrooms/core/timepiece'
import { useGetAccessRuleUrl } from '@sevenrooms/mgr-core/hooks/useGetAccessRuleUrl'
import { Link } from '@sevenrooms/react-components/components/Link'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesMatchCategoryMessages } from '../../../locales'
import { ExpandableCard } from '../Cards/ExpandableCard'
import { filterDuplicateReasons } from './accessRuleCategoryUtil'
import { TableCombosCategory } from './TableCombosCategory'
import { TablesCategory } from './TablesCategory'
import type { GroupedAvailabilityDebuggerReasons } from './reasonGroupingUtil'

interface AccessRulesMatchCategoryProps {
  accessRuleReasonData: GroupedAvailabilityDebuggerReasons
  defaultExpanded?: boolean
  covers: number
  date: DateOnly
  time: TimeOnly
  venue: Venue
  audiences: Record<string, string>
}

interface AccessRulesMatchDetailsProps {
  reason: AvailabilityDebuggerReason
  covers: number
  time: TimeOnly
  audiences: Record<string, string>
}

function AccessRulesMatchDetails({ reason, covers, time, audiences }: AccessRulesMatchDetailsProps) {
  const { formatMessage } = useLocales()

  let message
  switch (reason.reason) {
    case 'NO_INVENTORY_RESERVATIONS_REMAINING':
      message = formatMessage(accessRulesMatchCategoryMessages.mainDescriptionNoInventoryReservations, {
        time: time.formatSTime(),
      })
      break
    case 'NO_INVENTORY_COVERS_REMAINING':
      message = formatMessage(accessRulesMatchCategoryMessages.mainDescriptionNoInventoryCovers, {
        count: covers,
        time: time.formatSTime(),
      })
      break
    case 'CUTOFF_PASSED':
      message = formatMessage(accessRulesMatchCategoryMessages.mainDescriptionCutoffPassed, {
        cutoffNum: reason?.data?.bookingCutoffNum,
        cutoffType: reason?.data?.bookingCutoffType,
        cutoffHour: reason?.data?.bookingCutoffHour ? TimeOnly.from(reason?.data?.bookingCutoffHour).formatSTime() : 'RESERVATION_TIME',
      })
      break
    case 'NOT_REACHED_BOOKING_START_TIME':
      message = formatMessage(accessRulesMatchCategoryMessages.mainDescriptionNotReachedBookingStartTime, {
        startNum: reason?.data?.tierStartNum,
        startType: reason?.data?.tierStartType,
        startHour: reason?.data?.tierStartHour ? TimeOnly.from(reason?.data?.tierStartHour).formatSTime() : 'RESERVATION_TIME',
        audience: reason?.data?.audience ? audiences?.[reason?.data?.audience] : '',
      })
      break
    case 'NO_PACING_COVERS_REMAINING':
      message = formatMessage(accessRulesMatchCategoryMessages.mainDescriptionNoPacingCoversRemaining, {
        count: covers,
        covers,
        time: time.formatSTime(),
      })
      break
    case 'TAGS_MISMATCH':
      message = formatMessage(accessRulesMatchCategoryMessages.mainDescriptionTagsMismatch, {
        audience: reason?.data?.audience ? audiences?.[reason?.data?.audience] : '',
      })
      break
    default:
      message = null
  }

  return <Typography variant="body1">{message}</Typography>
}

export function AccessRulesMatchCategory({
  accessRuleReasonData,
  defaultExpanded = true,
  venue,
  covers,
  date,
  time,
  audiences,
}: AccessRulesMatchCategoryProps) {
  const { formatMessage } = useLocales()
  const getAccessRuleUrl = useGetAccessRuleUrl()

  const filteredAccessRuleReasons = filterDuplicateReasons(accessRuleReasonData.accessRuleMatchReasons)
  const formattedHeader = formatMessage(accessRulesMatchCategoryMessages.mainDescriptionHeader, {
    ruleName: accessRuleReasonData.accessRuleName,
    a: (chunks: string[]) => (
      <Link data-test="access-rule-id" href={getAccessRuleUrl(venue.urlKey, date, accessRuleReasonData.accessRuleId)} target="_blank">
        {chunks}
      </Link>
    ),
  })

  const allTableReasons = [...accessRuleReasonData.tablesMatchReasons, ...accessRuleReasonData.tablesTargetingReasons]
  const allComboReasons = [...accessRuleReasonData.comboMatchReasons, ...accessRuleReasonData.comboTargetingReasons]

  return (
    <ExpandableCard
      data-test={`access-rules-match-category-content-${accessRuleReasonData.accessRuleId}`}
      title={formattedHeader}
      startExpanded={defaultExpanded}
    >
      {filteredAccessRuleReasons.length > 0 ? (
        filteredAccessRuleReasons.map(reason => (
          <AccessRulesMatchDetails key={reason.reason} reason={reason} time={time} covers={covers} audiences={audiences} />
        ))
      ) : (
        <>
          <Typography variant="body1">{formatMessage(accessRulesMatchCategoryMessages.noTablesAvailable)}</Typography>
          {allTableReasons.length > 0 && <TablesCategory reasons={allTableReasons} date={date} venue={venue} />}
          {allComboReasons.length > 0 && <TableCombosCategory reasons={allComboReasons} date={date} venue={venue} />}
        </>
      )}
    </ExpandableCard>
  )
}
