import _ from 'lodash'
import React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { useVenueContext, useVenues, type Venue } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
// eslint-disable-next-line import/no-relative-packages
import LanguageContentLayout from '../../../../../application/site/static/app/component-lib/Manager/Layout/LanguageContent'
// eslint-disable-next-line import/no-relative-packages
import MessageBox from '../../../../../application/site/static/app/component-lib/Manager/MessageBox'
// eslint-disable-next-line import/no-relative-packages
import EditableLanguageTable from '../../../../../application/site/static/app/component-lib/Manager/MultiLanguage/LanguageTable/EditableLanguageTable'
// eslint-disable-next-line import/no-relative-packages
import history from '../../../../../application/site/static/app/manager/pages/src/shared/utils/History'
import { useCustomSmsContext, useVenueLanguages } from '../hooks'
import { customSmsMessages } from '../locales'

export interface CustomSmsLanguageStringsProps {
  routes: [{ name: string; route: string }]
  readonly onChangeVenue: (venue: Venue) => void
}

export function CustomSmsLanguageStrings({ routes, onChangeVenue }: CustomSmsLanguageStringsProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const userVenues = useVenues()
  const { mediaUrl } = useAppContext()
  const venues = userVenues ?? [venue]
  const venueId = venue?.id
  const {
    enabledLanguages,
    defaultLanguage,
    defaultLanguageCode,
    selectedLanguage,
    selectedLanguageCode,
    venueLanguageChanged,
    isLanguageListFetching,
  } = useVenueLanguages(venueId)

  const {
    customSmsList,
    updatedCustomSmsIds,
    updateCustomSms,
    addCustomSms,
    onSave,
    customSmsToDeleteId,
    onDelete,
    showDeleteModal,
    onDeleteConfirm,
    setShowDeleteModal,
    isLoading,
  } = useCustomSmsContext(venueId, selectedLanguageCode, defaultLanguageCode, isLanguageListFetching)

  const translatedLanguage = selectedLanguage && !selectedLanguage.isDefault ? [selectedLanguage] : []

  return (
    <LanguageContentLayout
      title={formatMessage(customSmsMessages.customSmsTitle)}
      venue={venue}
      venues={venues}
      routes={routes}
      history={history}
      onChangeVenue={onChangeVenue}
      languages={enabledLanguages}
      isLoading={isLoading}
      selectedLanguage={selectedLanguageCode}
      onChangeLanguage={venueLanguageChanged}
      mediaUrl={mediaUrl}
      saveFunction={() => {
        onSave(venue.id, customSmsList, updatedCustomSmsIds, selectedLanguageCode, defaultLanguageCode)
      }}
      overrideRefreshOnVenueChange
    >
      {!_.isEmpty(customSmsList) && (
        <>
          <EditableLanguageTable
            defaultLanguage={defaultLanguage}
            translatedLanguages={translatedLanguage}
            enabledLanguages={enabledLanguages}
            selectedLanguage={selectedLanguageCode}
            onChangeLanguage={venueLanguageChanged}
            handleCellChange={updateCustomSms}
            addNewRecordFunction={addCustomSms}
            data={customSmsList}
            onDelete={onDelete}
            descriptionHeaderText={formatMessage(customSmsMessages.customSmsTableHeaderText)}
            descriptionPlaceholderText={formatMessage(customSmsMessages.customSmsTableNamePlaceholderText)}
            languageColumnPlaceholderText={formatMessage(customSmsMessages.customSmsTableSmsMessagePlaceholderText)}
            addButtonText={formatMessage(customSmsMessages.customSmsTableCreateNewLabel)}
          />
          {showDeleteModal && (
            <MessageBox
              handleActionClick={() => {
                onDeleteConfirm(venue.id, customSmsToDeleteId)
              }}
              handleCloseClick={() => setShowDeleteModal(false)}
              dialogType={MessageBox.DialogType.INFO}
              header={formatMessage(customSmsMessages.customSmsDeleteModalTitle)}
              actionButtonText={formatMessage(customSmsMessages.customSmsDeleteModalButtonLabel)}
              explanation={formatMessage(customSmsMessages.customSmsDeleteModalMessage)}
            />
          )}
        </>
      )}
    </LanguageContentLayout>
  )
}
