import DatePicker from 'js/3p/react-datepicker-v1'
import moment from 'moment-timezone'
import styled from 'styled-components'
import { SNOOZE_OPTIONS } from 'mgr/pages/single-venue/settings/reducers/ordering/EightySixProduct'
import RadioButton from 'svr/component-lib/Generic/RadioButtons/RadioButton'
import type { PRODUCT_STATES } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'
import type { Moment } from 'moment'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const OptionContainer = styled.div`
  display: flex;
  align-items: center;

  .react-datepicker-v1 {
    margin-left: ${props => props.theme.gutter.standard};

    input {
      border-radius: 2px;
      border-color: ${props => props.theme.color.greySecondary};
    }
  }
`
export interface EightySixProductFormProps {
  selectedSnoozeOptionId: string
  onChangeSnoozeOption: (id: string) => void
  productStateId: PRODUCT_STATES
  selectedSpecificSnoozeDate: Moment
  onSpecificSnoozeDateChange: (date: Moment) => void
}
function EightySixProductForm({
  selectedSnoozeOptionId,
  onChangeSnoozeOption,
  productStateId,
  selectedSpecificSnoozeDate,
  onSpecificSnoozeDateChange,
}: EightySixProductFormProps) {
  const finalOptions = productStateId === 'snoozed' ? SNOOZE_OPTIONS : SNOOZE_OPTIONS.filter(option => option.id !== 'make_available')

  return (
    <Container>
      {finalOptions.map(option => (
        <OptionContainer key={option.id} data-test={`snooze-option-${option.id}`}>
          <RadioButton
            key={option.id}
            data-test={option.id}
            name="snoozeProduct"
            checked={selectedSnoozeOptionId === option.id}
            value={option.id}
            onChange={() => onChangeSnoozeOption(option.id)}
          >
            {option.display}
          </RadioButton>

          {option.id === 'until_specific_date' && selectedSnoozeOptionId === 'until_specific_date' && (
            <div className="svr-datepicker react-datepicker-v1">
              <DatePicker selected={selectedSpecificSnoozeDate} onChange={onSpecificSnoozeDateChange} minDate={moment().add(1, 'day')} />
            </div>
          )}
        </OptionContainer>
      ))}
    </Container>
  )
}

export default EightySixProductForm
