import type { AvailabilityDebuggerReason, Venue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/react-components/components/Button'
import { venueCategoryMessages } from '../../../locales'
import { NonexpandableCard } from '../Cards/NonexpandableCard'

interface VenueContentCategoryProps {
  venue: Venue
  reasons: AvailabilityDebuggerReason[]
}

export function VenueCategory({ venue, reasons }: VenueContentCategoryProps) {
  const { formatMessage } = useLocales()

  const reason = reasons[0]
  let contentText = ''
  let actionText = ''
  let actionUrl = ''
  let dataTest = ''
  switch (reason?.reason) {
    case 'VENUE_CLOSED': {
      contentText = formatMessage(venueCategoryMessages.mainDescriptionClosed)
      actionText = formatMessage(venueCategoryMessages.manageBlackoutDates)
      actionUrl = `/manager/${venue.urlKey}/manage/blackoutdates`
      dataTest = 'venue-category-blackout-dates-action-button'
      break
    }
    case 'NO_ACCESS_RULES_FOR_SHIFT': {
      contentText = formatMessage(venueCategoryMessages.mainDescriptionNoAccessRules)
      actionText = formatMessage(venueCategoryMessages.manageAccessRules)
      actionUrl = `/manager2/${venue.urlKey}/settings/availability/accessrules`
      dataTest = 'venue-category-access-rules-action-button'
      break
    }
    default:
      break
  }
  return (
    <NonexpandableCard
      data-test="venue-category-card"
      title={contentText}
      action={
        <Button data-test={dataTest} size="small" variant="contained" onClick={() => window.open(actionUrl, '_blank')}>
          {actionText}
        </Button>
      }
    />
  )
}
