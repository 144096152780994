import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import { useLocales } from '@sevenrooms/core/locales'
import { TimeLocale } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { useTheme } from '@sevenrooms/react-components/hooks/useTheme'
import { calendarMessages } from '../../locales'
import { renderOverrides, listTime } from '../../utils'
import { StatusIcon } from '../StatusIcon'
import { testEvents } from './testData'
import type { EventContentArg, DayHeaderContentArg } from '@fullcalendar/core'
import type { ReactNode } from 'react'

const listWidths = [10, 10, 12, 10, 16, 10, 20, 12]

export function PrivateEventsCalendar() {
  const theme = useTheme()
  const CalendarStyleOverride = renderOverrides(theme)

  return (
    <Box
      sx={{
        backgroundColor: t => t.palette.common.white,
        minWidth: '800px',
        padding: t => `0 ${t.spacing(8)} ${t.spacing(8)}`,
      }}
    >
      <CalendarStyleOverride />
      <ListHeader />
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
        initialView="timeGridWeek"
        eventTextColor={theme.palette.common.black}
        eventBackgroundColor={theme.palette.common.white}
        eventBorderColor={theme.palette.common.white}
        dayMaxEvents={3}
        headerToolbar={{
          left: 'dayGridMonth timeGridWeek timeGridDay listWeek',
        }}
        listDayFormat={{
          month: 'short',
          year: 'numeric',
          day: 'numeric',
          weekday: 'long',
        }}
        dayPopoverFormat={{
          month: 'long',
          year: 'numeric',
          day: 'numeric',
          weekday: 'short',
        }}
        listDaySideFormat={false}
        views={{
          timeGrid: {
            allDaySlot: false,
            eventContent: renderEventBlock,
            dayHeaderContent: (d: DayHeaderContentArg) => {
              const dayText = new Intl.DateTimeFormat(TimeLocale.getLocale(), { weekday: 'short' }).format(d.date)
              const dayOfMonth = d.date.getDate()
              return { html: `<span class='dow-text'>${dayText}</span><span class='date-text'>${dayOfMonth}</span>` }
            },
            slotLabelFormat: {
              hour: '2-digit',
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short',
            },
          },
          dayGrid: {
            eventContent: (info: EventContentArg) => renderEventBlock(info),
          },
          list: {
            eventContent: (info: EventContentArg) => <EventListItem info={info} />,
          },
        }}
        events={testEvents}
      />
    </Box>
  )
}

function renderEventBlock(info: EventContentArg) {
  return (
    <Box
      sx={{
        backgroundColor: '#F6E5EB',
        borderRadius: '4px',
        overflow: 'hidden',
        padding: t => t.spacing(1),
        width: '100%',
        height: '100%',
      }}
    >
      <Typography variant="body2">
        <strong>{info.event.title}</strong> {info.timeText}
      </Typography>
    </Box>
  )
}

function EventListItem({ info }: { info: EventContentArg }) {
  const theme = useTheme()
  const { formatMessage } = useLocales()
  const { venue, space, covers, phone, tables, status } = info.event.extendedProps

  const boxStyle = {
    padding: `${theme.spacing(2)} 1% ${theme.spacing(2)} 0`,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }

  return (
    <Stack
      direction="row"
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        lineHeight: '16px',
      }}
    >
      <Box sx={{ ...boxStyle, width: `${listWidths[0]}%` }}>{venue}</Box>
      <Box sx={{ ...boxStyle, width: `${listWidths[1]}%` }}>{space}</Box>
      <Box sx={{ ...boxStyle, width: `${listWidths[2]}%` }}>{listTime(info.event.start, info.event.end)}</Box>
      <Box sx={{ ...boxStyle, width: `${listWidths[3]}%` }}>
        {covers} {formatMessage(calendarMessages.guests)}
      </Box>
      <Box sx={{ ...boxStyle, width: `${listWidths[4]}%` }}>
        <strong>{info.event.title}</strong>
      </Box>
      <Box sx={{ ...boxStyle, width: `${listWidths[5]}%` }}>{phone}</Box>
      <Box sx={{ ...boxStyle, width: `${listWidths[6]}%` }}>{tables}</Box>
      <Box sx={{ ...boxStyle, width: `${listWidths[7]}%`, color: `${theme.palette.text.primary}` }}>
        <StatusIcon status={status.toLowerCase()} /> {status}
      </Box>
    </Stack>
  )
}

function ListHeader() {
  const theme = useTheme()
  const { formatMessage } = useLocales()
  const headers = [
    formatMessage(calendarMessages.listHeaderVenue),
    formatMessage(calendarMessages.listHeaderSpace),
    formatMessage(calendarMessages.listHeaderTime),
    formatMessage(calendarMessages.listHeaderCovers),
    formatMessage(calendarMessages.listHeaderEventName),
    formatMessage(calendarMessages.listHeaderPhone),
    formatMessage(calendarMessages.listHeaderTables),
    formatMessage(calendarMessages.listHeaderStatus),
  ]
  const headerDisplay: ReactNode[] = []
  headers.forEach((title, index) => {
    headerDisplay.push(<Box sx={{ width: `${listWidths[index]}%` }}>{title}</Box>)
  })
  return (
    <Stack
      direction="row"
      sx={{
        color: t => t.palette.grey[600],
        fontSize: '10px',
        letterSpacing: '0.15em',
        padding: `${theme.spacing(2)} 0`,
        textTransform: 'uppercase',
      }}
    >
      {headerDisplay}
    </Stack>
  )
}
