import { useFlatfile, Sheet, Workbook } from '@flatfile/react'
import { useSelector } from 'react-redux'
import { Desc, SubDesc } from 'mgr/pages/single-venue/settings/components/shared'
import { useLocales } from '@sevenrooms/core/locales'
import type { RootState } from '@sevenrooms/mgr-core'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Button } from '@sevenrooms/react-components/components/Button'
import { ImportLocales } from '../Import.locales'
import type { FlatFileFieldMapping, FlatFileResults, FlatFileRow, ImportType } from '../types'
import type { Flatfile } from '@flatfile/api'
import type { FlatfileRecord } from '@flatfile/hooks'

interface FlatFileImportProps {
  importType: ImportType
  dateFormat: string
  title: string
  fields: FlatFileFieldMapping[]
  handleFlatFileDone: (flatFileResults: FlatFileResults) => void
  handleClickBack: () => void
}

export default function FlatFileImport(props: FlatFileImportProps) {
  const { importType, dateFormat, title, fields, handleFlatFileDone, handleClickBack } = props
  const { formatMessage } = useLocales()
  const { openPortal, closePortal } = useFlatfile()
  const fieldKeys = fields.map(field => field.key)
  const fieldKeyTypeMapping = fields.reduce(
    (acc, field) => ({
      ...acc,
      [field.key]: field.type,
    }),
    {} as Record<string, string | undefined>
  )
  const { regexValidations, dateFields } = useSelector((state: RootState) => state.reservationAndClientImport)
  const dateRegexValidations = {
    'dd.mm.yyyy': '^(0?[1-9]|[12][0-9]|3[01])\\.(0?[1-9]|1[012])\\.\\d{4}$',
    'dd/mm/yyyy': '^(0?[1-9]|[12][0-9]|3[01])\\/(0?[1-9]|1[012])\\/\\d{4}$',
    'dd-mm-yyyy': '^(0?[1-9]|[12][0-9]|3[01])\\-(0?[1-9]|1[012])\\-\\d{4}$',

    'yyyy.mm.dd': '^\\d{4}\\.(0?[1-9]|1[012])\\.(0?[1-9]|[12][0-9]|3[01])$',
    'yyyy/mm/dd': '^\\d{4}\\/(0?[1-9]|1[012])\\/(0?[1-9]|[12][0-9]|3[01])$',
    'yyyy-mm-dd': '^\\d{4}\\-(0?[1-9]|1[012])\\-(0?[1-9]|[12][0-9]|3[01])$',

    'mm.dd.yyyy': '^(0?[1-9]|1[012])\\.(0?[1-9]|[12][0-9]|3[01])\\.\\d{4}$',
    'mm/dd/yyyy': '^(0?[1-9]|1[012])\\/(0?[1-9]|[12][0-9]|3[01])\\/\\d{4}$',
    'mm-dd-yyyy': '^(0?[1-9]|1[012])\\-(0?[1-9]|[12][0-9]|3[01])\\-\\d{4}$',

    'dd.mm': '^(0?[1-9]|[12][0-9]|3[01])\\.(0?[1-9]|1[012])$',
    'dd/mm': '^(0?[1-9]|[12][0-9]|3[01])\\/(0?[1-9]|1[012])$',
    'dd-mm': '^(0?[1-9]|[12][0-9]|3[01])\\-(0?[1-9]|1[012])$',

    'mm.dd': '^(0?[1-9]|1[012])\\.(0?[1-9]|[12][0-9]|3[01])$',
    'mm/dd': '^(0?[1-9]|1[012])\\/(0?[1-9]|[12][0-9]|3[01])$',
    'mm-dd': '^(0?[1-9]|1[012])\\-(0?[1-9]|[12][0-9]|3[01])$',
  }

  const handleRecordValidations = (record: FlatfileRecord) => {
    for (const dateField of dateFields || []) {
      const regex = new RegExp(dateRegexValidations[dateFormat as keyof typeof dateRegexValidations])
      const recordValue = String(record.get(dateField))
      if (record.get(dateField) && !regex.test(recordValue)) {
        record.addError(dateField, `Invalid value - dates must be of chosen format ${dateFormat}`)
      }
    }

    Object.entries(regexValidations || {}).forEach(([field, validation]) => {
      const regex = new RegExp(validation, 'i')
      const recordValue = String(record.get(field))
      if (record.get(field) && !regex.test(recordValue)) {
        record.addError(field, 'Invalid value')
      }
    })
    return record
  }

  const convertRecord = (record: Flatfile.RecordWithLinks, sequence: number) => {
    const data: FlatFileRow['data'] = { $custom: {} }
    for (const key in record.values) {
      if (Object.prototype.hasOwnProperty.call(record.values, key)) {
        if (fieldKeys.includes(key)) {
          const defaultValue = fieldKeyTypeMapping[key] === 'string' ? '' : null
          data[key] = record.values[key]?.value || defaultValue
          // empty string/null instead of undefined only for matching previous data format - backend should handle either
        } else {
          data.$custom[key] = record.values[key]?.value
        }
      }
    }

    return {
      sequence: sequence + 1,
      valid: record.valid || false,
      data,
      deleted: false,
      edited: false, // unused - only for matching previous data format
    }
  }

  const handleSubmit = async (data: {
    sheet?: {
      allData: () => {
        sheetId: string
        workbookId: string
        records: Flatfile.RecordsWithLinks
      }
    }
  }) => {
    const response = await data.sheet?.allData() // excludes deleted records
    const convertedResults = response?.records.map((record, index) => convertRecord(record, index))
    closePortal()
    handleFlatFileDone({ $data: convertedResults || [] })
  }

  return (
    <>
      <Desc>{formatMessage(ImportLocales.step2)}</Desc>
      <SubDesc>{formatMessage(ImportLocales.chosenDateFormat, { dateFormat })}</SubDesc>
      <SubDesc>{formatMessage(ImportLocales.uploadFile, { importType: importType.toString() })}</SubDesc>
      <Box marginTop="20px">
        <Button variant="contained" onClick={handleClickBack} style={{ marginRight: '20px' }} data-test="flatfile-import-back">
          {formatMessage(ImportLocales.backStep)}
        </Button>
        <Button variant="contained" onClick={openPortal} data-test="flatfile-import-start-upload">
          {formatMessage(ImportLocales.importFile)}
        </Button>
      </Box>
      <Workbook
        config={{
          name: title,
          actions: [
            {
              operation: 'workbookSubmitAction', // overriding default submit action to include constraints
              mode: 'foreground',
              label: 'Next',
              description: 'Action for handling data inside of onSubmit',
              primary: true,
              constraints: [{ type: 'hasAllValid' }, { type: 'hasData' }],
            },
          ],
        }}
        onSubmit={handleSubmit}
      >
        <Sheet
          config={{
            name: title,
            slug: title,
            allowAdditionalFields: true,
            fields: fields as Flatfile.Property[],
          }}
          onRecordHook={handleRecordValidations}
        />
      </Workbook>
    </>
  )
}
