/* eslint-disable camelcase */
// snake case required for usage in GenericTagsDropDown component
import type { Flatfile } from '@flatfile/api'

export enum ImportType {
  RESERVATION = 'reservation',
  CLIENT = 'client',
}
export interface TagGroupFromBackend {
  id: string
  name: string
  color_hex: string
  tags: string[]
}

export interface NewTagGroup extends TagGroupFromBackend {
  isNew: boolean
}

export type MappedTags = Record<
  string,
  {
    tagGroup: { name_display: string; tag_name_displays: {}; id: string; name: string; color_hex: string; tags: string[] }
    tagName: string
  }
>

export interface MappedTagFromBackend {
  tag_group_id: string
  tag_name: string
}

export interface FlatFileFieldMapping {
  key: string
  label: string
  constraints?: Record<string, string>[]
  type?: string
  config?: Record<string, string[]>
}

export interface ImportInitialData {
  mappedTagsFromBackend: Record<string, MappedTagFromBackend>
  fields: FlatFileFieldMapping[]
  regexValidations: Record<string, string>
  dateFields: string[]
  tagGroups: NewTagGroup[]
  publishableKey: string
}

export interface ReservationAndClientImportState {
  isInitialDataLoaded: boolean
  tagGroups: (TagGroupFromBackend | NewTagGroup)[]
  fields: FlatFileFieldMapping[]
  regexValidations?: Record<string, string>
  publishableKey?: string | null
  mappedTags: MappedTags
  blobstoreUploadKey: string | null
  importType: string | null
  importId: string | null
  isPopUpOpen: boolean
  isSubmitting: boolean
  dateFields?: string[]
}

export interface FlatFileRow {
  data: {
    $custom: Record<string, Flatfile.CellValueUnion | undefined | null>
    [key: string]: Flatfile.CellValueUnion | Record<string, Flatfile.CellValueUnion | undefined | null> | undefined | null
  }
}

export interface FlatFileResults {
  $data: FlatFileRow[]
}
