import { useMemo } from 'react'
import { useGetSeatingAreasTablesQueryNew } from '@sevenrooms/core/api'
import type { AvailabilityDebuggerReason, SeatingAreasCodesToTables, Venue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { DateOnly } from '@sevenrooms/core/timepiece'
import { Button } from '@sevenrooms/react-components/components/Button'
import { Link } from '@sevenrooms/react-components/components/Link'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { reasonsModalMessages, tablesCategoryMessages } from '../../../locales'
import { NestedCard } from '../Cards/NestedCard'
import { compareTableItems } from './tablesCategoryUtil'
import { getBlocksUrl, getReservationUrl } from './urlRedirectUtil'

interface TablesCategoryProps {
  reasons: AvailabilityDebuggerReason[]
  date: DateOnly
  venue: Venue
}

interface TablesDetailsProps {
  seatingAreaName: string
  reasons: AvailabilityDebuggerReason[]
  date: DateOnly
  venue: Venue
}

interface TablesGroupedReasons {
  HARD_ASSIGNED_RESERVATIONS: AvailabilityDebuggerReason[]
  AUTO_ASSIGNED_RESERVATIONS: AvailabilityDebuggerReason[]
  START_TIME_BLOCK: AvailabilityDebuggerReason[]
  BLOCKED: AvailabilityDebuggerReason[]
  NO_TABLES: AvailabilityDebuggerReason[]
  NOT_FOUND_IN_SHIFT_LAYOUT: AvailabilityDebuggerReason[]
  NOT_IN_SEATING_AREA: AvailabilityDebuggerReason[]
  NO_ACCESS_RULES: AvailabilityDebuggerReason[]
  PARTY_SIZE_MISMATCH: AvailabilityDebuggerReason[]
  INVALID_RULE_FOR_TABLE: AvailabilityDebuggerReason[]
  HELD_BY_ACCESS_RULE: AvailabilityDebuggerReason[]
}

function TablesDetails({ seatingAreaName, reasons, date, venue }: TablesDetailsProps) {
  const { formatMessage } = useLocales()

  const messages: string[] = []
  reasons.forEach(reason => {
    switch (reason.reason) {
      case 'HARD_ASSIGNED_RESERVATIONS':
        messages.push(
          formatMessage(tablesCategoryMessages.hardAssignedReservations, {
            table: reason.data?.tableItemCode,
            seatingAreaName,
            a: (chunks: string[]) =>
              reason.data?.shiftPersistentId && reason.data.slotActualId ? (
                <Link
                  data-test="reservation-link"
                  href={getReservationUrl(venue, date, reason.data?.shiftPersistentId, reason.data?.slotActualId)}
                  target="_blank"
                >
                  {chunks}
                </Link>
              ) : (
                <>{chunks}</>
              ),
          })
        )
        break
      case 'AUTO_ASSIGNED_RESERVATIONS':
        messages.push(
          formatMessage(tablesCategoryMessages.autoAssignedReservations, {
            table: reason.data?.tableItemCode,
            seatingAreaName,
            a: (chunks: string[]) =>
              reason.data?.shiftPersistentId && reason.data.slotActualId ? (
                <Link
                  data-test="reservation-link"
                  href={getReservationUrl(venue, date, reason.data?.shiftPersistentId, reason.data?.slotActualId)}
                  target="_blank"
                >
                  {chunks}
                </Link>
              ) : (
                <>{chunks}</>
              ),
          })
        )
        break
      case 'START_TIME_BLOCK':
        messages.push(
          formatMessage(tablesCategoryMessages.startTimeBlock, {
            table: reason.data?.tableItemCode,
            seatingAreaName,
            blockName: reason.data?.blockNames?.join(', '),
            a: (chunks: string[]) => (
              <Link data-test="blocks-url" href={getBlocksUrl(venue, date, reason.data?.shiftCategory as string)} target="_blank">
                {chunks}
              </Link>
            ),
          })
        )
        break
      case 'BLOCKED':
        messages.push(
          formatMessage(tablesCategoryMessages.blocked, {
            table: reason.data?.tableItemCode,
            seatingAreaName,
            blockName: reason.data?.blockNames?.join(', '),
            a: (chunks: string[]) => (
              <Link data-test="blocks-url" href={getBlocksUrl(venue, date, reason.data?.shiftCategory as string)} target="_blank">
                {chunks}
              </Link>
            ),
          })
        )
        break
      case 'NO_TABLES':
        reasons.forEach(reason => {
          messages.push(
            formatMessage(tablesCategoryMessages.noTables, {
              table: reason.data?.tableItemCode,
              seatingAreaName,
            })
          )
        })
        break
      case 'NOT_FOUND_IN_SHIFT_LAYOUT':
        messages.push(
          formatMessage(tablesCategoryMessages.notFoundInShiftLayout, {
            table: reason.data?.tableItemCode,
            seatingAreaName,
          })
        )
        break
      case 'NOT_IN_SEATING_AREA':
        messages.push(
          formatMessage(tablesCategoryMessages.notInSeatingArea, {
            table: reason.data?.tableItemCode,
            seatingAreaName,
          })
        )
        break
      case 'INVALID_RULE_FOR_TABLE':
        messages.push(formatMessage(tablesCategoryMessages.invalidRuleForTable, { table: reason.data?.tableItemCode, seatingAreaName }))
        break
      case 'PARTY_SIZE_MISMATCH':
        messages.push(
          formatMessage(tablesCategoryMessages.partySizeMismatch, {
            table: reason.data?.tableItemCode,
            seatingAreaName,
            min: reason.data?.tableMin,
            max: reason.data?.tableMax,
          })
        )
        break
      case 'HELD_BY_ACCESS_RULE':
        messages.push(formatMessage(tablesCategoryMessages.heldByAccessRule, { table: reason.data?.tableItemCode, seatingAreaName }))
        break
      default:
        break
    }
  })

  return (
    <Stack direction="column" display="flex" spacing={0.5} sx={{ pt: 0 }}>
      {messages.map(message => (
        <Typography key={message} variant="body1">
          {message}
        </Typography>
      ))}
    </Stack>
  )
}

function groupReasonsBySeatingArea(reasons: AvailabilityDebuggerReason[]) {
  const reasonsBySeatingArea: Record<string, AvailabilityDebuggerReason[]> = {}
  const reasonsWithoutSeatingAreas: AvailabilityDebuggerReason[] = []
  reasons.forEach(reason => {
    const seatingAreaId = reason.data?.seatingAreaId
    if (!seatingAreaId) {
      reasonsWithoutSeatingAreas.push(reason)
      return
    }
    if (!reasonsBySeatingArea[seatingAreaId]) {
      reasonsBySeatingArea[seatingAreaId] = []
    }
    reasonsBySeatingArea[seatingAreaId]?.push(reason)
    reasonsBySeatingArea[seatingAreaId]?.sort(compareTableItems)
  })

  const reasonsWithoutSeatingAreasSorted = reasonsWithoutSeatingAreas.sort(compareTableItems)
  return { reasonsBySeatingArea, reasonsWithoutSeatingAreas: reasonsWithoutSeatingAreasSorted }
}

function dedupeTableReasons(reasons: AvailabilityDebuggerReason[]) {
  const groupedReasons: TablesGroupedReasons = {
    HARD_ASSIGNED_RESERVATIONS: [],
    AUTO_ASSIGNED_RESERVATIONS: [],
    START_TIME_BLOCK: [],
    BLOCKED: [],
    NO_TABLES: [],
    NOT_FOUND_IN_SHIFT_LAYOUT: [],
    NOT_IN_SEATING_AREA: [],
    NO_ACCESS_RULES: [],
    PARTY_SIZE_MISMATCH: [],
    INVALID_RULE_FOR_TABLE: [],
    HELD_BY_ACCESS_RULE: [],
  }

  // group table reasons together and de-dupe
  reasons.forEach(r => {
    const reason = r.reason as keyof TablesGroupedReasons
    if (reason in groupedReasons) {
      if (groupedReasons[reason].length > 0) {
        const tc = r.data?.tableItemCode
        if (!groupedReasons[reason].some(r => r.data?.tableItemCode === tc)) {
          groupedReasons[reason].push(r)
        }
      } else {
        groupedReasons[reason].push(r)
      }
    }
  })

  return Object.values(groupedReasons).flat().sort(compareTableItems)
}

function getSeatingAreaNamesById(seatingAreas?: SeatingAreasCodesToTables[]) {
  if (!seatingAreas) {
    return {}
  }
  const seatingAreaNamesById: Record<string, string> = {}
  seatingAreas.forEach(seatingArea => {
    seatingAreaNamesById[seatingArea.id] = seatingArea.name
  })
  return seatingAreaNamesById
}

export function TablesCategory({ reasons, date, venue }: TablesCategoryProps) {
  const { formatMessage } = useLocales()
  const seatingAreasTables = useGetSeatingAreasTablesQueryNew({ venueId: venue.id })

  const dedupedReasons = useMemo(() => dedupeTableReasons(reasons), [reasons])
  const { reasonsBySeatingArea, reasonsWithoutSeatingAreas } = useMemo(() => groupReasonsBySeatingArea(dedupedReasons), [dedupedReasons])
  const seatingAreaNamesById = getSeatingAreaNamesById(seatingAreasTables.data?.newSeatingAreaCodesToTables)

  return (
    <NestedCard
      data-test="tables-category"
      title={formatMessage(reasonsModalMessages.tables)}
      action={
        <Button
          data-test="tables-category-action"
          size="small"
          variant="contained"
          onClick={() => window.open(`/manager/${venue.urlKey}/manage/capacity/table/edit`, '_blank')}
        >
          {formatMessage(reasonsModalMessages.manageTablesButtonText)}
        </Button>
      }
    >
      <Stack direction="column" display="flex" spacing={3}>
        {Object.entries(reasonsBySeatingArea).map(([seatingAreaId, reasons]) => (
          <TablesDetails
            key={seatingAreaId}
            seatingAreaName={seatingAreaNamesById[seatingAreaId] as string}
            reasons={reasons}
            date={date}
            venue={venue}
          />
        ))}
        {reasonsWithoutSeatingAreas.length > 0 && (
          <TablesDetails
            seatingAreaName={formatMessage(tablesCategoryMessages.noSeatingArea)}
            reasons={reasonsWithoutSeatingAreas}
            date={date}
            venue={venue}
          />
        )}
      </Stack>
    </NestedCard>
  )
}
