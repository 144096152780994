import constate from 'constate'
import { useState } from 'react'

function usePrivateEvents() {
  const [loaded, setLoaded] = useState<boolean>(false)

  return {
    loaded,
    setLoaded,
  }
}

export const [PrivateEventsContext, usePrivateEventsContext] = constate(usePrivateEvents)
