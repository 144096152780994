import type { AvailabilityDebuggerReason, Venue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { type DateOnly, TimeOnly, getHoursAndMinutes } from '@sevenrooms/core/timepiece'
import { useGetAccessRuleUrl } from '@sevenrooms/mgr-core/hooks/useGetAccessRuleUrl'
import { Link } from '@sevenrooms/react-components/components/Link'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesTargetingCategoryMessages } from '../../../locales'
import { ExpandableCard } from '../Cards/ExpandableCard'
import { filterDuplicateReasons } from './accessRuleCategoryUtil'
import type { GroupedAvailabilityDebuggerReasons } from './reasonGroupingUtil'

interface AccessRulesTargetingCategoryProps {
  accessRuleReasonData: GroupedAvailabilityDebuggerReasons
  defaultExpanded?: boolean
  date: DateOnly
  time: TimeOnly
  venue: Venue
  duration: number
}

interface AccessRulesTargetingDetailsProps {
  reason: AvailabilityDebuggerReason
  time: TimeOnly
  duration: number
}

function AccessRulesTargetingDetails({ reason, time, duration }: AccessRulesTargetingDetailsProps) {
  const { formatMessage } = useLocales()

  let message
  switch (reason.reason) {
    case 'PARTY_SIZE_RESTRICTION_MISMATCH': {
      const partyMin = reason.data?.isPartySizeRule ? reason.data.rulePartyMin : reason.data?.shiftPartyMin
      const partyMax = reason.data?.isPartySizeRule ? reason.data.rulePartyMax : reason.data?.shiftPartyMax
      message = formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionPartySizeRestrictionMismatch, {
        partyMin,
        partyMax,
      })
      break
    }
    case 'AUDIENCE_TIER_MISMATCH':
      message = formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionAudienceTierMismatch)
      break
    case 'DURATION_RESTRICTION_MISMATCH':
      message = formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionDurationRestrictionMismatch)
      break
    case 'NOT_ALLOWED_TIME':
      message = formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionNotAllowedTime, {
        count: reason.data?.accessRuleSpecificTimes?.length,
        time: time.formatSTime(),
        arTimes: reason.data?.accessRuleSpecificTimes?.map(time => TimeOnly.fromSafe(time)?.formatSTime()).join(', '),
      })
      break
    case 'NOT_WITHIN_TIME_RANGE':
      message = formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionNotWithinTimeRange, {
        time: time.formatSTime(),
        arTimeRangeStart: TimeOnly.fromSafe(reason.data?.timeRangeStart)?.formatSTime(),
        arTimeRangeEnd: TimeOnly.fromSafe(reason.data?.timeRangeEnd)?.formatSTime(),
      })
      break
    case 'NOT_DURATION_PICKER_ELIGIBLE':
      message = formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionNotDurationPickerEligible, {
        ...getHoursAndMinutes(duration),
      })
      break
    case 'ONLY_DURATION_PICKER_ELIGIBLE':
      message = formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionOnlyDurationPickerEligible)
      break
    case 'PRIVATE_EVENTS_TABLE_RESTRICTION':
      message = formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionPrivateEventsTableRestriction)
      break
    case 'PRIVATE_EVENTS_TABLE_COMBO_RESTRICTION':
      message = formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionPrivateEventsTableComboRestriction)
      break
    default:
      message = null
  }

  return <Typography variant="body1">{message}</Typography>
}

export function AccessRulesTargetingCategory({
  accessRuleReasonData,
  defaultExpanded = true,
  date,
  time,
  venue,
  duration,
}: AccessRulesTargetingCategoryProps) {
  const { formatMessage } = useLocales()
  const getAccessRuleUrl = useGetAccessRuleUrl()

  const filteredAccessRuleReasons = filterDuplicateReasons(accessRuleReasonData.accessRuleTargetingReasons)
  const formattedHeader = formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionHeader, {
    ruleName: accessRuleReasonData.accessRuleName,
    a: (chunks: string[]) => (
      <Link data-test="access-rule-id" href={getAccessRuleUrl(venue.urlKey, date, accessRuleReasonData.accessRuleId)} target="_blank">
        {chunks}
      </Link>
    ),
  })

  return (
    <ExpandableCard data-test="access-rules-targeting-category-card" title={formattedHeader} startExpanded={defaultExpanded}>
      {filteredAccessRuleReasons.map(reason => (
        <AccessRulesTargetingDetails key={reason.reason} reason={reason} time={time} duration={duration} />
      ))}
    </ExpandableCard>
  )
}
