const testDay1 = '2025-03-16'
const testDay2 = '2025-03-17'
const testDay3 = '2025-03-19'

const testData = {
  venue: 'Catch',
  space: 'Boiler Room',
  covers: 13,
  phone: '(917) 865-9090',
  tables: '101/102/103/104/220/221/303',
  status: 'Confirmed',
}

export const testEvents = [
  {
    ...testData,
    title: 'Some time',
    start: `${testDay1}T14:30:00`,
    end: `${testDay1}T15:30:00`,
    guests: 8,
  },
  {
    ...testData,
    title: 'Another time',
    start: `${testDay1}T15:30:00`,
    end: `${testDay1}T16:30:00`,
    guests: 8,
    highlight: true,
  },
  {
    ...testData,
    title: 'Some time',
    start: `${testDay1}T14:30:00`,
    end: `${testDay1}T15:30:00`,
    guests: 8,
  },
  {
    ...testData,
    title: 'Another time',
    start: `${testDay1}T15:30:00`,
    end: `${testDay1}T16:30:00`,
    guests: 8,
    highlight: true,
  },

  {
    ...testData,
    title: 'Some time',
    start: `${testDay2}T09:30:00`,
    end: `${testDay2}T13:30:00`,
    guests: 8,
  },
  {
    ...testData,
    title: 'Another time',
    start: `${testDay2}T11:00:00`,
    end: `${testDay2}T12:00:00`,
    guests: 8,
    highlight: true,
    status: 'Cancelled',
  },
  {
    ...testData,
    title: 'Some time',
    start: `${testDay2}T13:00:00`,
    end: `${testDay2}T15:30:00`,
    guests: 8,
    status: 'Request',
  },
  {
    ...testData,
    title: 'A Very Very Very Fine Time',
    start: `${testDay2}T14:30:00`,
    end: `${testDay2}T16:30:00`,
    guests: 8,
    highlight: true,
  },
  {
    ...testData,
    title: 'Some time',
    start: `${testDay2}T18:00:00`,
    end: `${testDay2}T19:00:00`,
    guests: 8,
    status: 'Booked',
  },
  {
    ...testData,
    title: 'Another time',
    start: `${testDay2}T20:00:00`,
    end: `${testDay2}T21:15:00`,
    guests: 8,
    highlight: true,
  },

  {
    ...testData,
    title: 'Some time',
    start: `${testDay3}T18:00:00`,
    end: `${testDay3}T19:00:00`,
    guests: 8,
  },
  {
    ...testData,
    title: 'Another time',
    start: `${testDay3}T20:00:00`,
    end: `${testDay3}T21:15:00`,
    guests: 8,
    highlight: true,
  },

  {
    ...testData,
    title: 'Some time',
    start: `${testDay3}T09:00:00`,
    end: `${testDay3}T12:00:00`,
    guests: 8,
  },
  {
    ...testData,
    title: 'Some time',
    start: `${testDay3}T10:00:00`,
    end: `${testDay3}T13:00:00`,
    guests: 8,
  },

  {
    ...testData,
    title: 'Another time',
    start: `${testDay3}T11:00:00`,
    end: `${testDay3}T14:15:00`,
    guests: 8,
    highlight: true,
  },
]
