import type { AvailabilityDebuggerReason, Venue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { TimeOnly } from '@sevenrooms/core/timepiece'
import { Button } from '@sevenrooms/react-components/components/Button'
import { inactiveCategoryMessages } from '../../../locales'
import { NonexpandableCard } from '../Cards/NonexpandableCard'

interface InactiveCategoryProps {
  reasons: AvailabilityDebuggerReason[]
  venue: Venue
  time: TimeOnly
}

export function InactiveCategory({ reasons, venue, time }: InactiveCategoryProps) {
  const { formatMessage } = useLocales()

  // This category is a full stop category, so we are guaranteed to only have one element in the array
  const shiftReason = reasons[0]

  let mainDescriptionText = ''
  let buttonText = ''
  let buttonLink = ''
  switch (shiftReason?.reason) {
    case 'PAST_TIME':
      mainDescriptionText = formatMessage(inactiveCategoryMessages.mainDescriptionPastTime)
      break
    case 'NO_SHIFT':
      mainDescriptionText = formatMessage(inactiveCategoryMessages.mainDescriptionNoShift, { time: time.formatSTime() })
      buttonText = formatMessage(inactiveCategoryMessages.noShiftButtonText)
      buttonLink = `/manager/${venue.urlKey}/manage/capacity/schedule`
      break
    default:
      break
  }
  return (
    <NonexpandableCard
      data-test="inactive-category-card"
      title={mainDescriptionText}
      action={
        buttonText ? (
          <Button
            data-test="inactive-category-button"
            size="small"
            variant="contained"
            onClick={() => window.open(`${buttonLink}`, '_blank')}
          >
            {buttonText}
          </Button>
        ) : null
      }
    />
  )
}
