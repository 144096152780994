import { useMemo } from 'react'
import type { AvailabilityDebuggerReason, Venue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { DateOnly } from '@sevenrooms/core/timepiece'
import { Button } from '@sevenrooms/react-components/components/Button'
import { Link } from '@sevenrooms/react-components/components/Link'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { reasonsModalMessages, tableCombosCategoryMessages } from '../../../locales'
import { NestedCard } from '../Cards/NestedCard'
import { compareTableItems } from './tablesCategoryUtil'
import { getBlocksUrl } from './urlRedirectUtil'

interface TableCombosCategoryProps {
  reasons: AvailabilityDebuggerReason[]
  date: DateOnly
  venue: Venue
}

interface TableCombosDetailsProps {
  reason: AvailabilityDebuggerReason
  date: DateOnly
  venue: Venue
}

interface CombosMatchGroupedReasons {
  START_TIME_BLOCK: AvailabilityDebuggerReason[]
  BLOCKED_AT_TIME: AvailabilityDebuggerReason[]
  NOT_ALL_AVAILABLE: AvailabilityDebuggerReason[]
  PARTY_SIZE_MISMATCH: AvailabilityDebuggerReason[]
  TABLE_COMBO_NOT_ALLOWED: AvailabilityDebuggerReason[]
  HELD_BY_ACCESS_RULE: AvailabilityDebuggerReason[]
}

function TableCombosDetails({ reason, date, venue }: TableCombosDetailsProps) {
  const { formatMessage } = useLocales()

  let message
  switch (reason.reason) {
    case 'START_TIME_BLOCK':
      message = formatMessage(tableCombosCategoryMessages.startTimeBlock, {
        combo: reason.data?.tableItemCodes,
        blockName: reason.data?.blockNames?.join(', '),
        a: (chunks: string[]) => (
          <Link data-test="blocks-url" href={getBlocksUrl(venue, date, reason.data?.shiftCategory as string)} target="_blank">
            {chunks}
          </Link>
        ),
      })
      break
    case 'BLOCKED_AT_TIME':
      message = formatMessage(tableCombosCategoryMessages.blockedAtTime, {
        combo: reason.data?.tableItemCodes,
        blockName: reason.data?.blockNames?.join(', '),
        a: (chunks: string[]) => (
          <Link data-test="blocks-url" href={getBlocksUrl(venue, date, reason.data?.shiftCategory as string)} target="_blank">
            {chunks}
          </Link>
        ),
      })
      break
    case 'NOT_ALL_AVAILABLE':
      message = formatMessage(tableCombosCategoryMessages.notAllAvailable, {
        combo: reason.data?.tableItemCodes,
      })
      break
    case 'PARTY_SIZE_MISMATCH':
      message = formatMessage(tableCombosCategoryMessages.partySizeMismatch, {
        combo: reason.data?.tableItemCodes,
        min: reason.data?.tableMin,
        max: reason.data?.tableMax,
      })
      break
    case 'TABLE_COMBO_NOT_ALLOWED':
      message = formatMessage(tableCombosCategoryMessages.tableComboNotAllowed, {
        combo: reason.data?.tableItemCodes,
      })
      break
    case 'HELD_BY_ACCESS_RULE':
      message = formatMessage(tableCombosCategoryMessages.heldByAccessRule, { combo: reason.data?.tableItemCodes })
      break
    default:
      message = null
  }

  return (
    <Typography key={message} variant="body1">
      {message}
    </Typography>
  )
}

function sortAndDedupeComboReasons(reasons: AvailabilityDebuggerReason[]) {
  const groupedReasons: CombosMatchGroupedReasons = {
    START_TIME_BLOCK: [],
    BLOCKED_AT_TIME: [],
    NOT_ALL_AVAILABLE: [],
    PARTY_SIZE_MISMATCH: [],
    TABLE_COMBO_NOT_ALLOWED: [],
    HELD_BY_ACCESS_RULE: [],
  }

  // group table reasons together and de-dupe
  reasons.forEach(r => {
    const reason = r.reason as keyof CombosMatchGroupedReasons
    if (reason in groupedReasons) {
      if (groupedReasons[reason].length > 0) {
        const tc = r.data?.tableItemCodes
        if (!groupedReasons[reason].some(r => r.data?.tableItemCodes === tc)) {
          groupedReasons[reason].push(r)
        }
      } else {
        groupedReasons[reason].push(r)
      }
    }
  })

  return Object.values(groupedReasons).flat().sort(compareTableItems)
}

export function TableCombosCategory({ reasons, date, venue }: TableCombosCategoryProps) {
  const { formatMessage } = useLocales()
  const sortedReasons = useMemo(() => sortAndDedupeComboReasons(reasons), [reasons])

  return (
    <NestedCard
      data-test="table-combos-category"
      title={formatMessage(reasonsModalMessages.tableCombinations)}
      action={
        <Button
          data-test="tables-category-action"
          size="small"
          variant="contained"
          onClick={() => window.open(`/manager/${venue.urlKey}/manage/table_combos`, '_blank')}
        >
          {formatMessage(reasonsModalMessages.manageTableCombosButtonText)}
        </Button>
      }
    >
      <Stack direction="column" display="flex">
        {sortedReasons.map(reason => (
          <TableCombosDetails key={`${reason}-${reason.data?.tableItemCodes}`} reason={reason} date={date} venue={venue} />
        ))}
      </Stack>
    </NestedCard>
  )
}
