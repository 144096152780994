import { useNavigation } from '@sevenrooms/core/navigation'
import type { SideNavigationCategoryItem } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'

export function useNavigationItems(): { navigationItems: Array<SideNavigationCategoryItem> } {
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const keyParam = { params: { venueKey: venue.urlKey } }

  const navigationItems = [
    {
      id: 'general',
      title: 'General',
      allowed: true,
      collapsible: false,
      items: [
        {
          id: 'calendar',
          title: 'Calendar',
          to: nav.href(routes.manager2.privateEvents.calendar, keyParam),
          items: [],
          reload: false,
          exact: true,
        },
      ],
    },
  ]

  return { navigationItems }
}
