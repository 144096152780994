import { useCallback, useMemo } from 'react'
import { PrivateEventsCalendar } from '@sevenrooms/private-events-calendar'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Button } from '@sevenrooms/react-components/components/Button'

export function Calendar() {
  useMemo(() => {
    // eslint-disable-next-line no-console
    window.ReservationSlideOut.onBookNew(() => console.log('Handle errors/sucess for creating requests; close'))
  }, [])

  const createReservation = useCallback(() => {
    window.SvrManager.ActualSlideout.addReservation()
  }, [])

  const loadRequest = useCallback(() => {
    const requestUrl = '/manager/catch/requests/all?rid=ahFkZXZ-bmlnaHRsb29wLW5ld3IpCxIcbmlnaHRsb29wX1Jlc2VydmF0aW9uUmVxdWVzdBiAgICA8M_pCgw'
    window.open(requestUrl)
  }, [])

  const createRequest = useCallback(() => {
    const date = new Date()
    window.ReservationSlideOut.bookNew(date, undefined, undefined, 'force_request')
  }, [])

  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'auto',
        background: t => t.palette.grey[50],
      }}
    >
      <Box>
        <PrivateEventsCalendar />
      </Box>
      <Box m="l">
        <Button data-test="test-res-slideout" onClick={() => createReservation()}>
          Open Reservation Slideout
        </Button>
      </Box>
      <Box m="l">
        <Button data-test="test-req-slideout" onClick={() => createRequest()}>
          Open New Request Slideout
        </Button>
      </Box>
      <Box m="l">
        <Button data-test="test-req-slideout" onClick={() => loadRequest()}>
          Open Existing Request Slideout
        </Button>
      </Box>
    </Box>
  )
}
